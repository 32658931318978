import formatIncomeExpenseValue from './formatIncomeExpenseValue';

const businessSettingsTransformer = ({
  __typename,
  industry,
  id,
  yearlyIncomePeriod,
  yearlyExpensesPeriod,
  ...data
}) => {
  return {
    variables: {
      businessSetting: {
        ...data,
        isVatRegistered: data.isVatRegistered === 'true',
        yearlyIncome:
          yearlyIncomePeriod === 'month'
            ? formatIncomeExpenseValue(data.yearlyIncome) * 12
            : formatIncomeExpenseValue(data.yearlyIncome),

        yearlyExpenses:
          yearlyExpensesPeriod === 'month'
            ? formatIncomeExpenseValue(data.yearlyExpenses) * 12
            : formatIncomeExpenseValue(data.yearlyExpenses),
      },
    },
  };
};

export default businessSettingsTransformer;
