import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MaterialCard from '@material-ui/core/Card';

const useStyles = makeStyles({
  card: {
    border: '1px solid #E3E3E3',
    borderRadius: '5px',
    boxShadow: 'none',
  },
  narrow: {
    maxWidth: '800px',
    margin: '0 auto',
  },
  xNarrow: {
    maxWidth: '600px',
    margin: '0 auto',
  },

  xxNarrow: {
    maxWidth: '500px',
    margin: '0 auto',
  },
  noActions: {
    boxShadow: 'none',
    '& > :last-child': {
      paddingBottom: '40px',
    },
  },
  bigShadow: {
    boxShadow: '0px 0px 100px rgba(0, 0, 0, 0.1)',
  },
  marginBottom: {
    marginBottom: '30px',
  },
  marginTop: {
    marginTop: '30px',
  },
  center: {
    textAlign: 'center',
  },
  flex: {
    flex: 1,
  },
});

const Card = ({
  narrow,
  xNarrow,
  xxNarrow,
  noActions,
  children,
  bigShadow,
  marginBottom,
  marginTop,
  center,
  flex,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MaterialCard
      className={clsx(classes.card, {
        [classes.narrow]: narrow,
        [classes.xNarrow]: xNarrow,
        [classes.xxNarrow]: xxNarrow,
        [classes.noActions]: noActions,
        [classes.bigShadow]: bigShadow,
        [classes.marginBottom]: marginBottom,
        [classes.marginTop]: marginTop,
        [classes.center]: center,
        [classes.flex]: flex,
      })}
      {...props}
    >
      {children}
    </MaterialCard>
  );
};

Card.propTypes = {
  narrow: PropTypes.bool,
  xNarrow: PropTypes.bool,
  xxNarrow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  noActions: PropTypes.bool,
  bigShadow: PropTypes.bool,
  marginBottom: PropTypes.bool,
  marginTop: PropTypes.bool,
  center: PropTypes.bool,
  flex: PropTypes.bool,
};

Card.defaultProps = {
  narrow: false,
  xNarrow: false,
  xxNarrow: false,
  noActions: false,
  bigShadow: false,
  marginBottom: false,
  marginTop: false,
  center: false,
  flex: false,
};

export default Card;
