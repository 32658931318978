import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';

import Tooltip from '../Tooltip';

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: 0,
    lineHeight: 1.4,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  hasHelpText: {
    paddingRight: '50px',
  },
  marginBottom: {
    marginBottom: ' 8px',
  },
  optionalText: {
    color: theme.palette.grey[400],
    fontSize: 12,
    textTransform: 'uppercase',
    marginLeft: 'auto',
    display: 'block',
  },
  visuallyHidden: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
}));

const Label = ({
  id,
  label,
  helpText,
  marginBottom,
  optional,
  visuallyHidden,
}) => {
  const classes = useStyles();

  return (
    <FormLabel
      htmlFor={id}
      className={clsx(classes.label, {
        [classes.marginBottom]: marginBottom,
        [classes.hasHelpText]: !!helpText,
        [classes.visuallyHidden]: visuallyHidden,
      })}
    >
      <span className={classes.labelText}>{label}</span>

      {optional && <span className={classes.optionalText}>Optional</span>}

      {helpText && <Tooltip helpText={helpText} />}
    </FormLabel>
  );
};

Label.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  marginBottom: PropTypes.bool,
  optional: PropTypes.bool,
  visuallyHidden: PropTypes.bool,
};

Label.defaultProps = {
  helpText: null,
  marginBottom: false,
  optional: false,
  visuallyHidden: false,
};

export default Label;
