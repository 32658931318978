import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';

import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';

import Button from '../Button';

import { GET_MENU_STATE } from '../../graph/navigation';

const useStyles = makeStyles((theme) => ({
  cardActions: {
    borderTop: 'solid 1px rgba(0, 0, 0, 0.08)',
    display: 'block',
    padding: '20px 30px',

    [theme.breakpoints.up('sm')]: {
      padding: '20px 30px',
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row-reverse',
    },
  },
  noMobilePadding: {
    padding: '20px 0',

    [theme.breakpoints.up('sm')]: {
      padding: '20px 30px',
    },
  },
  fixed: {
    backgroundColor: theme.appBar.backgroundColor,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    flex: 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.drawer.widthSmall,
      width: `calc(100% - ${theme.drawer.widthSmall}px)`,
    },
  },
  fixedShift: {
    marginLeft: theme.drawer.width,
    width: `calc(100% - ${theme.drawer.width}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  noBorder: {
    borderTop: 'none',
  },

  noActionBar: {
    borderTop: 'none',
    paddingTop: 0,
    paddingBottom: 20,
    marginTop: -20,

    [theme.breakpoints.up('sm')]: {
      paddingBottom: 30,
    },
  },

  multipleButtons: {
    justifyContent: 'space-between',
  },
  center: {
    justifyContent: 'center',
  },
  button: {
    width: '100%',
    margin: '0 0 10px 0',
    '&:not(:first-child)': {
      margin: '0 0 10px 0',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      margin: '0',

      '&:not(:first-child)': {
        margin: '0',
      },
    },
  },
  fullWidth: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  actionButton: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.blue.dark,
    },
  },
  actionLeft: {
    marginRight: 'auto',
  },
  space: {
    justifyContent: 'space-between',
  },
  maxWidth: {
    maxWidth: 440,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const ActionBar = ({
  button,
  secondaryButton,
  disabled,
  actionButton,
  noBorder,
  noActionBar,
  center,
  fixed,
  noMobilePadding,
  space,
  maxWidth,
}) => {
  const classes = useStyles();

  const { data } = useQuery(GET_MENU_STATE);

  const menuOpen = data?.menuState?.main;

  return (
    <CardActions
      className={clsx(classes.cardActions, {
        [classes.multipleButtons]: secondaryButton || (actionButton && center),
        [classes.center]: center,
        [classes.noBorder]: noBorder,
        [classes.noActionBar]: noActionBar,
        [classes.fixed]: fixed,
        [classes.fixedShift]: fixed && menuOpen,
        [classes.noMobilePadding]: noMobilePadding,
        [classes.space]: space,
        [classes.maxWidth]: maxWidth,
      })}
    >
      {button && (
        <Button
          {...button}
          disabled={disabled || button.disabled}
          component={button.to ? Link : 'button'}
          type={button.onClick || button.to ? 'button' : 'submit'}
          className={clsx(classes.button, {
            [classes.fullWidth]: button.fullWidth,
          })}
        >
          {button.label}
        </Button>
      )}

      {actionButton && (
        <Button
          {...actionButton}
          disabled={disabled || actionButton.disabled}
          component={actionButton.to ? Link : 'button'}
          type={actionButton.onClick || actionButton.to ? 'button' : 'submit'}
          className={clsx(classes.button, classes.actionButton, {
            [classes.actionLeft]: !secondaryButton,
          })}
        >
          {actionButton.label}
        </Button>
      )}

      {secondaryButton && (
        <Button
          disabled={disabled || secondaryButton.disabled}
          {...secondaryButton}
          component={secondaryButton.to ? Link : 'button'}
          type={
            secondaryButton.onClick || secondaryButton.to ? 'button' : 'submit'
          }
          className={classes.button}
        >
          {secondaryButton.label}
        </Button>
      )}
    </CardActions>
  );
};

ActionBar.propTypes = {
  button: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    to: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
  }),
  secondaryButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    to: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  actionButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    to: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  noBorder: PropTypes.bool,
  noActionBar: PropTypes.bool,
  center: PropTypes.bool,
  fixed: PropTypes.bool,
  noMobilePadding: PropTypes.bool,
  space: PropTypes.bool,
  maxWidth: PropTypes.bool,
};

ActionBar.defaultProps = {
  disabled: false,
  button: null,
  secondaryButton: null,
  actionButton: null,
  noBorder: false,
  center: false,
  fixed: false,
  noActionBar: false,
  noMobilePadding: false,
  space: false,
  maxWidth: false,
};

export default ActionBar;
