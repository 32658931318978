import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LayoutAuth from '../../components/LayoutAuth';
import Button from '../../components/Button';
import OnboardingHeader from '../../components/OnboardingHeader';
import OnboardingVisualisation from '../../components/OnboardingVisualisation';
import OnboardingHelpBlock from '../../components/OnboardingHelpBlock';

import { sleep } from '../../utils';

import referrers from '../../config/referrers';

const RegisterLanding = ({ referralCode, referrerName }) => {
  const [currentHelpIndex, setCurrentHelpIndex] = useState();
  const [showSideContent, setShowSideContent] = useState(false);

  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'select', {
        send_to: 'UA-151517575-1',
        event_category: 'Register Landing',
      });
    }
  }, []);

  const generateUrl = () => {
    if (referralCode && referrerName)
      return `/onboarding/${referralCode}/${referrerName}`;
    if (referralCode) return `/onboarding/${referralCode}`;
    return `/onboarding`;
  };

  const handleShowHelp = (i) => {
    setCurrentHelpIndex(i);
    setShowSideContent(true);

    if (window.gtag) {
      window.gtag('event', 'select', {
        send_to: 'UA-151517575-1',
        event_category: 'Register Landing Help',
        event_value: i,
      });
    }
  };

  const handleCloseSideContent = async () => {
    setShowSideContent(false);
    await sleep(300);
    setCurrentHelpIndex(null);
  };

  const helpContent = [
    {
      id: '1',
      title: 'Stay on top of your business finances',
      copy: "See reports on your income, expenses and profit each month. Real-time tax estimates make sure you're always on top of what you owe.",
    },
    {
      id: '2',
      title: 'Simplify income and expense tracking',
      copy: 'Use Open Banking to securely pull up your bank transactions (read-only). Tag them as business or personal, and create automated rules so Finmo tags regular transactions for you.',
    },
    {
      id: '3',
      title: 'Snap and store receipts, invoices and documents',
      copy: 'Quickly take and upload pictures of your receipts and invoices to store in Finmo. Digitally match your receipts and invoices to your bank transactions so everything is in one place and you never miss an expense. ',
    },
    {
      id: '4',
      title: 'File your tax return with confidence',
      copy: 'Simplify the tax filing process with our easy-to-use tax return software that reduces the time spent filing and improves accuracy, ensuring you don’t miss anything. ',
    },
    {
      id: '5',
      title: 'Expert help tailored to your needs',
      copy: "You'll get access to sole trader tax and accounting experts who are available to answer your questions as you have them. Upgrade your account to match with one of our bookkeepers who will sort your books each month and present them for your approval.",
    },
  ];

  const { id } = referrers[referrerName] || {};

  const renderSideContent = () => {
    if (!currentHelpIndex && currentHelpIndex !== 0) return false;
    const { title, copy } = helpContent[currentHelpIndex];
    return <OnboardingHelpBlock title={title} copy={copy} pink />;
  };

  return (
    <LayoutAuth
      referral={id}
      currentStep={1}
      totalSteps={7}
      showSideContent={showSideContent}
      renderSideContent={renderSideContent}
      onCloseSideContent={handleCloseSideContent}
    >
      <OnboardingHeader
        header="Discover a better way to handle tax."
        subheader="Finmo streamlines every aspect of sole trader accounting and tax management. Stay on top of your business finances, snap and store receipts and invoices, and make filing easier with experts and bookkeepers available to answer questions or take the accounting admin off your hands."
        marginBottom
      />

      <OnboardingVisualisation
        onShowHelp={handleShowHelp}
        steps={helpContent}
      />

      <Button
        variant="contained"
        color="secondary"
        largeCentered
        to={generateUrl()}
      >
        Let’s go!
      </Button>
    </LayoutAuth>
  );
};

RegisterLanding.propTypes = {
  referralCode: PropTypes.string,
  referrerName: PropTypes.string,
};

RegisterLanding.defaultProps = {
  referralCode: null,
  referrerName: null,
};

export default RegisterLanding;
