import React, { useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';

import LayoutAuth from '../../components/LayoutAuth';

import { GET_TOKEN, IS_AUTHENTICATED } from '../../graph/auth';

const AppLinkAuth = ({ accessToken, refreshToken, redirect }) => {
  const client = useApolloClient();

  useEffect(() => {
    if (!accessToken || !refreshToken) return;
    client.cache.writeQuery({
      query: IS_AUTHENTICATED,
      data: {
        isAuthenticated: true,
      },
    });

    client.writeQuery({
      query: GET_TOKEN,
      data: { accessToken, refreshToken },
    });

    navigate(redirect ? `/${decodeURIComponent(redirect)}` : '/');
  }, [accessToken, client, refreshToken, redirect]);

  return <LayoutAuth fetching />;
};

AppLinkAuth.propTypes = {
  accessToken: PropTypes.string.isRequired,
  refreshToken: PropTypes.string.isRequired,
  redirect: PropTypes.string,
};

AppLinkAuth.defaultProps = {
  redirect: null,
};

export default AppLinkAuth;
