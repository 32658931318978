const getTypePrefix = type => {
  if (type === 'Residential Property') return 'resi';
  if (type === 'Other Property, assets and gains') return 'prop';
  if (type === 'Listed Shares or Securities') return 'shares';
  return 'unlisted';
};

const getCapitalGainsCalcuations = data =>
  data &&
  data.reduce(
    (a, item) => {
      const type = getTypePrefix(item.assetType);
      const gains = item.saleAmount - item.purchaseAmount - item.expenses;
      const gainsPrefix = gains < 0 ? 'Losses' : 'Gains';

      return {
        ...a,
        [`${type}NumberOfDisposals`]: a[`${type}NumberOfDisposals`] + 1,
        [`${type}DisposalProceeds`]:
          a[`${type}NumberOfDisposals`] + item.saleAmount,
        [`${type}AllowableCosts`]: a[`${type}AllowableCosts`] + item.expenses,
        [`${type}TaxOnGainAlreadyCharged`]:
          a[`${type}TaxOnGainAlreadyCharged`] + (item.taxAmountCharged || 0),
        [`${type}${gainsPrefix}InTheYear`]:
          a[`${type}${gainsPrefix}InTheYear`] + gains,
      };
    },
    {
      propNumberOfDisposals: 0,
      propDisposalProceeds: 0,
      propAllowableCosts: 0,
      propTaxOnGainAlreadyCharged: 0,
      propGainsInTheYear: 0,
      propLossesInTheYear: 0,
      resiNumberOfDisposals: 0,
      resiDisposalProceeds: 0,
      resiAllowableCosts: 0,
      resiTaxOnGainAlreadyCharged: 0,
      resiGainsInTheYear: 0,
      resiLossesInTheYear: 0,
      sharesNumberOfDisposals: 0,
      sharesDisposalProceeds: 0,
      sharesAllowableCosts: 0,
      sharesTaxOnGainAlreadyCharged: 0,
      sharesGainsInTheYear: 0,
      sharesLossesInTheYear: 0,
      unlistedNumberOfDisposals: 0,
      unlistedDisposalProceeds: 0,
      unlistedAllowableCosts: 0,
      unlistedTaxOnGainAlreadyCharged: 0,
      unlistedGainsInTheYear: 0,
      unlistedLossesInTheYear: 0,
    },
  );

export default getCapitalGainsCalcuations;
