import React from 'react';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import Section from '../Section';
import TextContent from '../TextContent';
import LandingTitle from '../LandingTitle';

import image from '../../assets/app-screenshot.png';

const HowItWorks = ({ ...props }) => {
  return (
    <Section bottomImage={image} {...props}>
      <LandingTitle>How it works</LandingTitle>

      <Grid container spacing={5}>
        <Grid item sm={3}>
          <TextContent>
            <Avatar>1</Avatar>

            <h3>
              Import your transactions, tag or add your income and expenses
            </h3>

            <p>
              Import your transactions to synchronise your business
              transactions. Tag and add your business transactions so your Finmo
              Accountant knows your exact income and expenses.{' '}
              <a href="https://finmo.co.uk/2019/12/23/how-finmo-connects-to-your-bank-accounts/">
                Learn more.
              </a>
            </p>
          </TextContent>
        </Grid>

        <Grid item sm={3}>
          <TextContent>
            <Avatar>2</Avatar>

            <h3>Answer a few simple questions online</h3>

            <p>
              Answer a few Self Assessment questions online. Finmo only asks you
              to answer questions relevant to your situation. All questions are
              written in easy to understand language.
            </p>
          </TextContent>
        </Grid>

        <Grid item sm={3}>
          <TextContent>
            <Avatar>3</Avatar>
            <h3>Consult with a Finmo Accountant to optimise your filing</h3>

            <p>
              You will be matched with a certified and licensed Finmo Accountant
              best suited to prepare your return. Talk to them about the best
              ways to optimise your tax return.
            </p>
          </TextContent>
        </Grid>

        <Grid item sm={3}>
          <TextContent>
            <Avatar>4</Avatar>
            <h3>File Self assessment</h3>

            <p>
              Once both you and your Finmo Accountant are happy with your
              return, your Finmo Accountant will file the Assessment with HMRC.
            </p>
          </TextContent>
        </Grid>
      </Grid>
    </Section>
  );
};

export default HowItWorks;
