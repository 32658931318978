/**
 * Utility function to extract a user readable error message fromt he GraphQL response
 *
 * @param {array} error - Error array
 * @return {string} Formatted error string
 *
 */

const getErrorMessage = (error) => {
  if (!error)
    return 'There was a problem processing your payment, plese try again or contact support.';

  if (!error?.message) return error;

  return error.message.replace('GraphQL error: ', '');
};

export default getErrorMessage;
