import React from 'react';
import PropTypes from 'prop-types';

import SendIcon from '@material-ui/icons/Send';
import FavoriteIcon from '@material-ui/icons/Favorite';

import { ReactComponent as SignLogo } from '../../assets/sign.svg';
import { ReactComponent as ShapeLogo } from '../../assets/shape.svg';
import { ReactComponent as CastleLogo } from '../../assets/castle.svg';
import { ReactComponent as BankLogo } from '../../assets/bank.svg';

import { baseColors } from '../../theme/base';

const JobIcon = ({ jobType }) => {
  if (!jobType) return false;

  if (jobType === 'freelance') {
    return <CastleLogo />;
  }

  if (jobType === 'rental') {
    return <SignLogo />;
  }

  if (jobType === 'earner') {
    return <ShapeLogo />;
  }

  if (jobType === 'investment') {
    return <BankLogo />;
  }

  if (jobType === 'personal') {
    return <FavoriteIcon style={{ fill: baseColors.purpleHeart }} />;
  }

  if (jobType === 'taxRelief') {
    return <SendIcon style={{ fill: baseColors.greenishTeal }} />;
  }

  return false;
};

JobIcon.propTypes = {
  jobType: PropTypes.string.isRequired,
};

export default JobIcon;
