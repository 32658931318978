import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { navigate } from '@reach/router';
import * as yup from 'yup';
import Cookies from 'js-cookie';

import Form from '../Form';

import { registerForm } from '../../config/FormFields';

import { validate, requiredString } from '../../utils/validate';

import {
  CREATE_USER,
  GET_TOKEN,
  UPDATE_PREVIOUS_YEAR_SELF_ASSESSMENT,
  IS_AUTHENTICATED,
} from '../../graph/auth';

const registerFormValidationSchema = validate({
  name: requiredString,
  email: requiredString.email(),
  agreeTerms: yup
    .bool()
    .required(
      'You must accept our terms and privacy policy to register with Finmo.',
    )
    .oneOf(
      [true],
      'You must accept our terms and privacy policy to register with Finmo.',
    ),
});

const RegisterForm = ({ redirectUrl, referralCode, eventLabel, ...props }) => {
  const client = useApolloClient();

  useEffect(() => {
    if (!referralCode || referralCode === 'null') return;
    Cookies.set('FinmoReferralCode', referralCode);
  }, [referralCode]);

  const referralCodeFromCookie = Cookies.get('FinmoReferralCode');

  const registerFormInitialValues = {
    name: '',
    email: '',
    password: '',
    referralCode: referralCode || referralCodeFromCookie || '',
    agreeTerms: '',
  };

  const [handleUpload] = useMutation(UPDATE_PREVIOUS_YEAR_SELF_ASSESSMENT);

  const [handleRegister, { loading, error }] = useMutation(CREATE_USER, {
    async onCompleted({ signUp }) {
      if (!signUp) return;

      if (window.fbq) {
        window.fbq('track', 'CompleteRegistration', {
          value: 5,
          currency: 'GBP',
        });
      }

      if (window.gtag) {
        window.gtag('event', 'conversion', {
          send_to: `${process.env.REACT_APP_AW_ID}/pGeYCLSUm7kBENbrjvMC`,
          user_id: signUp?.user?.id,
        });

        window.gtag('event', 'conversion', {
          send_to: 'UA-151517575-1',
          event_category: 'signup',
          event_label: eventLabel || 'signup',
          user_id: signUp?.user?.id,
        });
      }

      await client.writeQuery({
        query: GET_TOKEN,
        data: signUp,
      });

      navigate(redirectUrl);

      client.cache.writeQuery({
        query: IS_AUTHENTICATED,
        data: {
          isAuthenticated: true,
        },
      });
    },
  });

  const handleRegisterSubmit = async ({
    variables: {
      password,
      name,
      file,
      agreeTerms,
      additionalNotes,
      ...variables
    },
  }) => {
    await handleRegister({
      variables: {
        ...variables,
        name: name || 'Optimiser',
        password: password || process.env.REACT_APP_TEMP_PASSWORD,
        acceptedTermsAt: new Date(),
      },
    });

    if (file) {
      handleUpload({
        variables: {
          file,
          additionalNotes,
        },
      });
    }
  };

  return (
    <Form
      noMobilePadding
      noCard
      fields={registerForm}
      loading={loading}
      error={error}
      onSubmit={handleRegisterSubmit}
      validationSchema={registerFormValidationSchema}
      initialValues={registerFormInitialValues}
      noActionBar
      maxWidthButton
      narrow
      button={{
        label: 'Continue',
        color: 'secondary',
        variant: 'contained',
        fullWidth: true,
      }}
      {...props}
    />
  );
};

RegisterForm.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  referralCode: PropTypes.string,
  eventLabel: PropTypes.string,
};

RegisterForm.defaultProps = {
  referralCode: null,
  eventLabel: null,
};

export default RegisterForm;
