import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Link } from '@reach/router';

import Typography from '@material-ui/core/Typography';
import LayoutAuth from '../../components/LayoutAuth';
import Form from '../../components/Form';
import OnboardingHeader from '../../components/OnboardingHeader';

import { resetForm } from '../../config/FormFields';
import { validate, requiredString } from '../../utils/validate';

import { RESET_PASSWORD } from '../../graph/auth';

const resetFormValidationSchema = validate({
  email: requiredString,
});

const resetFormInitialValues = {
  email: '',
};

const ResetPassword = () => {
  const [resetComplete, setResetComplete] = useState(false);

  const [handleReset, { loading, error }] = useMutation(RESET_PASSWORD, {
    onCompleted({ resetUserPassword }) {
      if (!resetUserPassword || !resetUserPassword.hasReset) return;
      setResetComplete(true);
    },
  });

  const renderContent = () => {
    if (resetComplete) {
      return (
        <OnboardingHeader
          header="Check Your Email"
          subheader=" Instructions have been sent to your inbox on how to reset your
          password."
          marginBottom
        />
      );
    }

    return (
      <>
        <OnboardingHeader
          header="Reset Password"
          subheader="Please enter the email you registered with."
        />

        <Form
          errorMessage="Email address does not exist"
          noCard
          fields={resetForm}
          loading={loading}
          error={error}
          onSubmit={handleReset}
          validationSchema={resetFormValidationSchema}
          initialValues={resetFormInitialValues}
          noActionBar
          maxWidthButton
          narrow
          button={{
            label: 'Send Instructions',
            color: 'secondary',
            variant: 'contained',
            fullWidth: true,
          }}
        />
      </>
    );
  };

  return (
    <LayoutAuth>
      {renderContent()}

      <Typography align="center" gutterBottom color="primary">
        <Link style={{ color: 'inherit' }} to="/">
          Back to login
        </Link>
      </Typography>
    </LayoutAuth>
  );
};

export default ResetPassword;
