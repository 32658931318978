/**
 * Utility function to format dates
 *
 * @param {string} date - Date string
 * @param {string} dateFormat - Date format string
 * @return {string} Formatted date string
 *
 */

import dayjs from 'dayjs';

const formatDate = (date, item, dateFormat = 'DD-MMM-YY') =>
  date && dayjs(date).format(dateFormat);

export default formatDate;
