const matchPath = (itemPath, currentLocation) => {
  if (!itemPath || !currentLocation) return false;

  const isDashboard = itemPath === '/' || currentLocation === '/';

  if (isDashboard && itemPath === currentLocation) return true;

  if (!isDashboard && itemPath.includes(currentLocation)) return true;

  return false;
};

export default matchPath;
