const formDataToFieldType = ({
  answers,
  selfAssessmentId,
  jobId,
  answerType,
}) =>
  answers &&
  Object.entries(answers).reduce((a, [key, val]) => {
    return [
      ...a,
      {
        selfAssessmentId,
        jobId,
        answerType,
        identifier: key,
        value: val,
      },
    ];
  }, []);

export default formDataToFieldType;
