import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import Label from '../Label';

const useStyles = makeStyles({
  label: {
    marginBottom: '8px',
    lineHeight: 1.4,
  },
  radio: {
    marginBottom: '-8px',
  },
});

const RadioField = ({
  options,
  name,
  hintText,
  disabled,
  readOnly,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Field
      name={name}
      render={({ field, form: { errors, touched, submitCount } }) => {
        const errorMessage =
          ((touched && touched[field.name]) || submitCount > 0) &&
          errors &&
          errors[field.name];

        return (
          <FormControl fullWidth error={!!errorMessage}>
            <Label {...field} {...props} />

            <RadioGroup {...field} value={`${field.value}`} row>
              {options &&
                options.map(({ id, value, label: optionLabel }) => (
                  <FormControlLabel
                    key={id}
                    id={id}
                    value={value}
                    control={<Radio />}
                    label={optionLabel}
                    labelPlacement="end"
                    className={classes.radio}
                    disabled={disabled || readOnly}
                  />
                ))}
            </RadioGroup>

            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}

            {hintText && <FormHelperText>{hintText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

RadioField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hintText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

RadioField.defaultProps = {
  hintText: null,
  disabled: false,
  readOnly: false,
};

export default RadioField;
