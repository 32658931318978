import React from 'react';

import Grid from '@material-ui/core/Grid';

import Section from '../Section';
import TextContent from '../TextContent';
import LandingTitle from '../LandingTitle';

const WhyUseFinmo = ({ ...props }) => {
  return (
    <Section
      action={{ href: '#getStarted', color: 'secondary', variant: 'contained' }}
      {...props}
    >
      <LandingTitle>Why use Finmo?</LandingTitle>

      <Grid container spacing={5}>
        <Grid item sm={3}>
          <TextContent>
            <h3>Expert Advice</h3>

            <p>
              Optimise your expenses and save money on your tax bill. Our expert
              Accountants will guide you and answer your questions.
            </p>
          </TextContent>
        </Grid>

        <Grid item sm={3}>
          <TextContent>
            <h3>Quick and Easy</h3>

            <p>
              The Finmo Method was designed to save you time and money. Tracking
              and tagging your business income and expenses has never been
              easier.
            </p>
          </TextContent>
        </Grid>

        <Grid item sm={3}>
          <TextContent>
            <h3>£159 + VAT. All In.</h3>

            <p>
              You only pay £159 + VAT no more, no less. This is not an estimate
              and there is no chance of you paying more if your taxes get
              complicated.
            </p>
          </TextContent>
        </Grid>

        <Grid item sm={3}>
          <TextContent>
            <h3>Safe and Secure</h3>

            <p>
              Finmo uses bank level security and is FCA registered. We make sure
              your information is safe.
            </p>
          </TextContent>
        </Grid>
      </Grid>
    </Section>
  );
};

export default WhyUseFinmo;
