import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Section from '../Section';
import LandingTitle from '../LandingTitle';

import bgImage from '../../assets/yellow-background.png';
import image from '../../assets/app-screenshot-2.png';

const useStyles = makeStyles({
  footer: {
    backgroundColor: '#363839',
    color: 'rgba(255,255,255,0.5)',
    fontSize: '12px',
    padding: '20px 0',
    textAlign: 'center',

    '& a': {
      color: 'rgba(255,255,255,0.5)',
    },
  },

  marginTop: {
    marginTop: 'auto',
  },
});

const LandingFooter = ({
  footerCtaProps,
  noFooterCta,
  marginTop,
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      {!noFooterCta && (
        <Section
          action={{
            href: '#getStarted',
            color: 'white',
            variant: 'contained',
            ...footerCtaProps,
          }}
          backgroundImage={bgImage}
          bottomImage={image}
          {...props}
        >
          <LandingTitle white>
            Join Finmo and start your Self Assessment for only £159 + VAT.
          </LandingTitle>
        </Section>
      )}

      <footer
        className={clsx(classes.footer, { [classes.marginTop]: marginTop })}
      >
        <Container>
          Finmo © Copyright 2020 |{' '}
          <a
            href="https://finmo.co.uk/terms-and-conditions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>{' '}
          |{' '}
          <a
            href="https://finmo.co.uk/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </Container>
      </footer>
    </>
  );
};

LandingFooter.propTypes = {
  footerCtaProps: PropTypes.shape({}),
  noFooterCta: PropTypes.bool,
  marginTop: PropTypes.bool,
};

LandingFooter.defaultProps = {
  footerCtaProps: {},
  noFooterCta: false,
  marginTop: false,
};

export default LandingFooter;
