import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import { makeStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import JobIcon from '../JobIcon';
import Label from '../Label';

import { tagColors } from '../../theme/base';

import { GET_JOBS, GET_USER_JOBS } from '../../graph/jobs';

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: 30,
    fontSize: theme.typography.body2.fontSize,
    width: '100%',
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '&:hover': {
      opacity: 0.7,
    },
    '& option': {
      all: 'unset',
    },
    marginTop: '8px',
  },
  inputInner: {
    padding: '6px 30px 6px 12px',
  },
  dropDownItem: {
    padding: '0 10px 0 12px',
    minHeight: 42,
    minWidth: 220,
  },
  dropDownItemText: {
    fontSize: theme.typography.body2.fontSize,
  },
  actionBar: {
    padding: '5px 10px 0 5px',
    minHeight: 42,
    display: 'flex',
    justifyContent: 'space-between',
  },
  listItemIcon: {
    minWidth: '40px',
  },
}));

const JobSelectField = ({
  readOnly,
  disabled,
  errorMessage,
  field,
  values,
  userId,
  ...props
}) => {
  const classes = useStyles();

  const { loading: fetching, data } = useQuery(
    userId ? GET_USER_JOBS : GET_JOBS,
    {
      variables: { userId },
      fetchPolicy: 'cache-only',
    },
  );

  const job = data?.jobs?.find((j) => j.id === field.value);

  const hasTransactionType = !!values?.transactionType || !!values?.ruleType;

  const filteredJobs = hasTransactionType
    ? data?.jobs?.filter(
        (jobItem) =>
          ((values?.transactionType === 'DEBIT' ||
            values?.ruleType === 'EXPENSE') &&
            jobItem?.jobType.expenseTag) ||
          ((values?.transactionType === 'CREDIT' ||
            values?.ruleType === 'INCOME') &&
            jobItem?.jobType.incomeTag),
      )
    : [];

  return (
    <>
      {' '}
      <Label {...field} {...props} />
      <Select
        style={{
          backgroundColor: job
            ? tagColors[job?.jobType?.name]
            : tagColors.untagged,
          color: job ? 'white' : 'inherit',
        }}
        className={classes.input}
        variant="filled"
        error={!!errorMessage}
        value={field.value || ''}
        disabled={!hasTransactionType || fetching || disabled}
        readOnly={readOnly}
        displayEmpty
        inputProps={{ className: classes.inputInner }}
        renderValue={(selected) =>
          selected
            ? (filteredJobs || []).find((jobItem) => jobItem.id === selected)
                ?.name
            : 'Select a tag'
        }
        {...field}
      >
        {filteredJobs &&
          filteredJobs.map(
            ({ id: jobId, name: jobName, jobType: jobTypeItem }) => (
              <MenuItem
                className={classes.dropDownItem}
                key={jobId}
                value={jobId}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <JobIcon jobType={jobTypeItem?.name} />
                </ListItemIcon>

                <ListItemText
                  primaryTypographyProps={{
                    className: classes.dropDownItemText,
                  }}
                  primary={jobName}
                />
              </MenuItem>
            ),
          )}
      </Select>
    </>
  );
};

JobSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hintText: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  field: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  values: PropTypes.shape({
    transactionType: PropTypes.string,
    ruleType: PropTypes.string,
  }),
  userId: PropTypes.string,
};

JobSelectField.defaultProps = {
  label: null,
  readOnly: false,
  hintText: null,
  disabled: false,
  errorMessage: null,
  values: null,
  userId: null,
};

export default JobSelectField;
