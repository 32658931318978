import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

import { baseColors } from '../../theme/base';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px 0',
  },
  bar: {
    marginRight: 4,
    flex: 1,
    height: 8,
    borderRadius: 2,
    backgroundColor: 'rgba(200, 200, 200, .2)',
  },
  weak: {
    backgroundColor: baseColors.blushPink,
  },
  mid: {
    backgroundColor: baseColors.yellowOrange,
  },
  strong: {
    backgroundColor: baseColors.greenishTeal,
  },
  score: {
    paddingLeft: '15px',
    color: theme.palette.grey[500],
  },
}));

const PasswordScore = ({ score }) => {
  const classes = useStyles();

  return (
    <Collapse in={!!(score || score === 0)} timeout="auto" unmountOnExit>
      <div className={classes.container}>
        <div
          className={clsx(classes.bar, {
            [classes.weak]: score >= 0,
            [classes.mid]: score > 1,
            [classes.strong]: score > 2,
          })}
        />
        <div
          className={clsx(classes.bar, {
            [classes.mid]: score > 1,
            [classes.strong]: score > 2,
          })}
        />
        <div className={clsx(classes.bar, { [classes.strong]: score > 2 })} />
        <div className={classes.score}>{score > 2 ? 'Strong' : 'Weak'}</div>
      </div>
    </Collapse>
  );
};

PasswordScore.propTypes = {
  score: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

PasswordScore.defaultProps = {
  score: null,
};

export default PasswordScore;
