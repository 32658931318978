import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '../Button';

const useStyles = makeStyles({
  container: {
    paddingTop: '30px',
    textAlign: 'center',
    margin: '0 -30px 10px',
  },
  title: {
    fontWeight: 200,
    marginBottom: 20,
  },
  largeMarginBottom: {
    marginBottom: 30,
  },
});

const OnboardingPrompt = ({ title, button, to, largeMarginBottom }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, {
        [classes.largeMarginBottom]: largeMarginBottom,
      })}
    >
      <Typography align="center" variant="h5" className={classes.title}>
        {title}
      </Typography>

      <Button variant="outlined" color="secondary" to={to}>
        {button}
      </Button>
    </div>
  );
};

OnboardingPrompt.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  largeMarginBottom: PropTypes.bool,
};

OnboardingPrompt.defaultProps = {
  largeMarginBottom: false,
};

export default OnboardingPrompt;
