const getPricingInformation = ({
  products,
  name,
  taxYear,
  productId,
  upgrade,
}) => {
  return (
    products?.find(
      ({
        id,
        name: productName,
        taxYear: productTaxYear,
        upgrade: productUpgrade,
      }) =>
        (!name || productName === name) &&
        (!productId || id === productId) &&
        (!taxYear || taxYear === productTaxYear?.year) &&
        (!upgrade || productUpgrade === upgrade),
    ) || { disabled: true }
  );
};

export default getPricingInformation;
