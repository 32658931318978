/**
 * @format
 * @flow
 */

import gql from 'graphql-tag';

const GRANT_OAUTH = gql`
  mutation grantOauth(
    $scope: [Scope]!
    $clientId: String!
    $redirectUri: String!
  ) {
    grantOauth(scope: $scope, clientId: $clientId, redirectUri: $redirectUri) {
      code
      expires
      scope
      client
    }
  }
`;

const GET_AUTH_DATA = gql`
  query GetNotes($scopeIds: [ID]!, $clientId: ID!) {
    oauthScopes(ids: $scopeIds) {
      id
      title
      description
    }
    oauthPartner(id: $clientId) {
      id
      name
      description
      color
      logoSrc
    }
  }
`;

const GET_ACTIVE_TOKENS = gql`
  query OauthActiveTokens {
    oauthActiveTokens {
      scope
      client {
        id
        name
        description
        logoSrc
        color
      }
    }
  }
`;

const REVOKE_ACTIVE_TOKEN = gql`
  mutation RevokeOauth($clientId: String!) {
    revokeOauth(clientId: $clientId)
  }
`;

export { GET_AUTH_DATA, GRANT_OAUTH, GET_ACTIVE_TOKENS, REVOKE_ACTIVE_TOKEN };
