import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  notification: {
    padding: '30px',
    textAlign: 'center',
    maxWidth: '500px',
    margin: '0 auto',
    color: theme.palette.grey[500],
    '& svg': {
      width: '32px',
      fontSize: '32px',
      display: 'block',
      margin: '0 auto',
      marginBottom: '10px',
    },
  },
  noTopPadding: {
    padding: '0 30px 30px',
  },
  note: {
    backgroundColor: theme.palette.secondary.lighter,
    padding: '20px',
    maxWidth: 'none',
    margin: 0,
    color: theme.palette.grey[700],
    '& svg': {
      color: theme.palette.secondary.main,
      width: '24px',
      height: '24px',
    },
  },
  large: {
    fontSize: 16,
    paddingTop: 50,

    '& button, & a': {
      display: 'block',
      width: '100%',
      marginBottom: '10px',

      [theme.breakpoints.up('md')]: {
        margin: '0 5px',
        width: 200,
        display: 'inline-block',
      },
    },
  },
}));

const FormNotification = ({ label, icon, note, largeEmptyMessage }) => {
  const classes = useStyles();

  return (
    <p
      className={clsx(classes.notification, {
        [classes.noTopPadding]: !icon,
        [classes.note]: note,
        [classes.large]: largeEmptyMessage,
      })}
    >
      {icon && icon}
      {label}
    </p>
  );
};

FormNotification.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  note: PropTypes.bool,
  largeEmptyMessage: PropTypes.bool,
};

FormNotification.defaultProps = {
  icon: null,
  note: false,
  largeEmptyMessage: false,
};

export default FormNotification;
