import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import clsx from 'clsx';
import dayjsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';

import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Label from '../Label';

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: '8px',
  },
  small: {
    maxWidth: '240px',
  },
  adornment: {
    whiteSpace: 'nowrap',
    '& p': {
      lineHeight: '1.1875em',
    },
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
}));

const DateField = ({ name, small, hintText, disabled, readOnly, ...props }) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={dayjsUtils}>
      <Field
        name={name}
        render={({
          field,
          form: { errors, touched, submitCount, setFieldValue },
        }) => {
          const errorMessage =
            ((touched && touched[field.name]) || submitCount > 0) &&
            errors &&
            errors[field.name];

          const handleChange = (date) => {
            setFieldValue(name, dayjs(date).format('YYYY-MM-DD'));
          };

          return (
            <FormControl fullWidth error={!!errorMessage}>
              <Label {...field} {...props} />

              <KeyboardDatePicker
                disableToolbar
                variant="dialog"
                inputVariant="outlined"
                classes={{
                  root: clsx(classes.text, { [classes.small]: small }),
                }}
                name={name}
                error={!!errorMessage}
                disabled={disabled}
                readOnly={readOnly}
                placeholder="DD/MM/YYYY"
                {...field}
                onChange={handleChange}
                value={field.value}
                format="DD/MM/YYYY"
              />

              {errorMessage && (
                <FormHelperText error>{errorMessage}</FormHelperText>
              )}

              {hintText && <FormHelperText>{hintText}</FormHelperText>}
            </FormControl>
          );
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
  label: PropTypes.string,
  hintText: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

DateField.defaultProps = {
  small: false,
  disabled: false,
  label: null,
  hintText: null,
  readOnly: false,
};

export default DateField;
