import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import HelpButton from '../HelpButton';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    margin: '100px 0',

    [theme.breakpoints.down('sm')]: {
      margin: '0 0 30px 0',
    },
  },
  stepper: {
    padding: 0,
    flexWrap: 'wrap',

    '& .MuiStepConnector-alternativeLabel': {
      top: 21,
      zIndex: 1,

      '& .MuiStepConnector-lineHorizontal': {
        borderColor: '#C4C4C4',
      },
    },

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  step: {
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      flex: '0 0 50%',
      marginBottom: '20px',

      '& .MuiStepConnector-root': {
        display: 'none',
      },
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flex: '0 0 100%',
    },

    padding: '0 24px',

    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      fontWeight: 300,
      fontSize: 18,
    },
  },
  icon: {
    width: 42,
    height: 42,
    fill: '#5DB37F',
    zIndex: 2,
  },
}));

const OnboardingVisualisation = ({ steps, onShowHelp }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Stepper alternativeLabel classes={{ root: classes.stepper }}>
        {steps.map(({ title, id }, i) => (
          <Step key={id} active classes={{ root: classes.step }}>
            <StepLabel
              StepIconProps={{
                completed: true,
                classes: {
                  root: classes.icon,
                },
              }}
            >
              {title}
              <HelpButton onClick={() => onShowHelp(i)} />
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

OnboardingVisualisation.propTypes = {
  onShowHelp: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default OnboardingVisualisation;
