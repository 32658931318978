import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

const FileField = ({ name }) => {
  return (
    <Field
      name={name}
      render={({ field: { value } }) => {
        if (!value) return false;

        return (
          <FormControl fullWidth>
            <Button href={value} target="_blank" rel="noopener noreferrer">
              View file
            </Button>
          </FormControl>
        );
      }}
    />
  );
};

FileField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FileField;
