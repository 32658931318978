const getIntercomDataFromUser = ({ user, jobs }) => {
  const filteredJobs = jobs?.length
    ? jobs?.filter(
        (job) =>
          job?.jobType?.name !== 'taxRelief' &&
          job?.jobType?.name !== 'personal',
      )
    : [];
  const jobNameAndType = filteredJobs.length
    ? filteredJobs.reduce(
        (a, job, i) => ({
          ...a,
          [`job_${i + 1}_name`]: job?.name,
          [`job_${i + 1}_type`]: job?.jobType?.name,
        }),
        {},
      )
    : { finmo: true };

  return {
    user_id: user.id,
    email: user.email,
    name: user.name,
    phone: user.phoneNumber,
    How_can_we_help: user.howToHelp,
    first_time: user.firstTime,
    active_subscription_name: user.activeSubscriptionName,
    referral_partner_name: user.referralPartner?.name,
    finmo: true,
    ...user,
    ...jobNameAndType,
  };
};

export default getIntercomDataFromUser;
