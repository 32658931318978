import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MaterialCardHeader from '@material-ui/core/CardHeader';

const useStyles = makeStyles({
  cardHeader: {
    padding: '20px 50px',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.08)',
  },
  smallPadding: {
    padding: '20px 30px',
  },
  cardHeaderContent: {
    textAlign: 'center',
  },
  noBorder: {
    padding: '60px 0 20px',
    borderBottom: 0,
  },
});

const CardHeader = ({ centerHeader, noBorder, smallPadding, ...props }) => {
  const classes = useStyles();

  return (
    <MaterialCardHeader
      className={clsx(classes.cardHeader, {
        [classes.noBorder]: noBorder,
        [classes.smallPadding]: smallPadding,
      })}
      classes={{
        content: clsx({
          [classes.cardHeaderContent]: centerHeader,
        }),
      }}
      {...props}
    />
  );
};

CardHeader.propTypes = {
  centerHeader: PropTypes.bool,
  noBorder: PropTypes.bool,
  smallPadding: PropTypes.bool,
};

CardHeader.defaultProps = {
  centerHeader: false,
  noBorder: false,
  smallPadding: false,
};

export default CardHeader;
