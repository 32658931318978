/**
 * Utility function to format currency
 *
 * @param {number} value - Value as a floating point number
 * @return {string} Formatted curreny value
 *
 */

const formatCurrency = (value, noDecimals, noNegative) => {
  const formatter = new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: noDecimals ? 0 : 2,
    maximumFractionDigits: noDecimals ? 0 : 2,
  });

  const limitedValue = noNegative ? Math.max(value, 0) : value;

  return formatter.format(limitedValue || 0);
};

export default formatCurrency;
