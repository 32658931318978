/**
 * Utility function to convert an array of answers into a form value object
 *
 * @param {array} array - Array of keys
 * @return {object} Form values
 *
 */

import camelCase from 'lodash/camelCase';

const categoryTotalsToValueOjbect = array =>
  array &&
  array.reduce(
    (a, { hmrcCategory, total }) => ({
      ...a,
      [`${camelCase(hmrcCategory.displayName)}Total`]: total,
    }),
    {},
  );

export default categoryTotalsToValueOjbect;
