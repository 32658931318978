import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';

import Label from '../Label';

import { GET_JOBS } from '../../graph/jobs';

import { filterJobsByDocumentType } from '../../utils';

const useStyles = makeStyles({
  field: {
    marginTop: '8px',
  },
});

const JobDocumentField = ({
  hintText,
  errorMessage,
  documentGroup,
  field,
  disabled,
  ...props
}) => {
  const classes = useStyles();

  const { loading: fetching, data } = useQuery(GET_JOBS, {
    fetchPolicy: 'cache-only',
    skip: !documentGroup,
  });

  const { disabled: jobDisabled, jobs } = filterJobsByDocumentType(
    data,
    documentGroup,
  );

  return (
    <FormControl fullWidth error={!!errorMessage}>
      <Label {...field} {...props} />

      <NativeSelect
        input={<OutlinedInput {...field} />}
        className={classes.field}
        error={!!errorMessage}
        {...field}
        disabled={!documentGroup || fetching || jobDisabled || disabled}
        value={field.value || ''}
      >
        {jobs.map(({ id, name: jobName }) => (
          <option key={id} value={id}>
            {jobName}
          </option>
        ))}
      </NativeSelect>

      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}

      {hintText && <FormHelperText>{hintText}</FormHelperText>}
    </FormControl>
  );
};

JobDocumentField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hintText: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  documentGroup: PropTypes.string,
  field: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
};

JobDocumentField.defaultProps = {
  label: null,
  hintText: null,
  errorMessage: null,
  documentGroup: null,
  disabled: false,
};

export default JobDocumentField;
