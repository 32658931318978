import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Button from '../Button';
import HelpButton from '../HelpButton';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    maxWidth: 620,
    margin: '0 auto',
  },
  header: {
    color: theme.palette.common.black,
    fontWeight: 300,
    fontSize: 30,
    marginBottom: 12,
  },
  subheader: {
    color: theme.palette.grey[700],
    margin: '0 auto',
    fontSize: 18,
    fontWeight: 300,
    maxWidth: 440,
  },
  large: {
    fontSize: 48,
    marginBottom: 20,
  },
  marginBottom: {
    marginBottom: 60,
  },
  marginBottomMedium: {
    marginBottom: 30,
  },
  marginBottomSmall: {
    marginBottom: 20,
  },
  marginBottomXSmall: {
    marginTop: 20,
    marginBottom: 5,
  },
  icon: {
    width: 50,
    height: 50,
    margin: '0 auto 20px',

    '& svg': {
      width: 50,
      height: 50,
      display: 'block',
    },
  },
  wideDescription: {
    maxWidth: 480,
  },
}));

const OnboardingHeader = ({
  header,
  header2,
  subheader,
  subheader2,
  large,
  marginBottom,
  marginBottomMedium,
  action,
  onShowHelp,
  icon,
  wideDescription,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, {
        [classes.marginBottom]: marginBottom,
        [classes.marginBottomMedium]: marginBottomMedium,
      })}
    >
      {!!icon && <div className={classes.icon}>{icon}</div>}

      <Typography
        className={clsx(classes.header, { [classes.large]: large })}
        variant="h3"
      >
        {header} {!!onShowHelp && <HelpButton large onClick={onShowHelp} />}
      </Typography>

      {!!header2 && (
        <Typography
          className={clsx(classes.header, { [classes.large]: large })}
          variant="h3"
        >
          {header2}
        </Typography>
      )}

      <Typography
        className={clsx(classes.subheader, {
          [classes.marginBottomSmall]: !!action,
          [classes.marginBottomXSmall]: !!subheader2,
          [classes.wideDescription]: wideDescription,
        })}
        variant="body1"
      >
        {subheader}
      </Typography>

      {!!subheader2 && (
        <Typography
          className={clsx(classes.subheader, {
            [classes.marginBottomSmall]: !!action,
            [classes.wideDescription]: wideDescription,
          })}
          variant="body1"
        >
          {subheader2}
        </Typography>
      )}

      {!!action && <Button {...action}>{action.label}</Button>}
    </div>
  );
};

OnboardingHeader.propTypes = {
  header: PropTypes.string.isRequired,
  header2: PropTypes.string,
  subheader: PropTypes.string,
  subheader2: PropTypes.string,
  large: PropTypes.bool,
  marginBottom: PropTypes.bool,
  marginBottomMedium: PropTypes.bool,
  action: PropTypes.shape({
    label: PropTypes.string,
  }),
  onShowHelp: PropTypes.func,
  icon: PropTypes.node,
  wideDescription: PropTypes.bool,
};

OnboardingHeader.defaultProps = {
  header2: null,
  subheader: null,
  subheader2: null,
  large: false,
  marginBottom: false,
  marginBottomMedium: false,
  action: null,
  onShowHelp: undefined,
  icon: null,
  wideDescription: false,
};

export default OnboardingHeader;
