import { useQuery } from '@apollo/react-hooks';

import { GET_JOB, GET_JOBS, GET_USER_JOBS } from '../graph/jobs';

const useJobDetails = (jobId, type, userId) => {
  const isUntagged = jobId === 'untagged';

  // Otherwise fetch jobs
  const { loading: fetching, data } = useQuery(
    jobId ? GET_JOB : userId ? GET_USER_JOBS : GET_JOBS, // eslint-disable-line
    {
      variables: { jobId, userId },
      skip: isUntagged,
    },
  );

  // If there's no job ID and the type is untagged - we don't need to fetch a job
  if (isUntagged) {
    return {
      fetchingJobs: fetching,
      jobName: 'untagged',
      jobType: 'untagged',
      jobId: null,
    };
  }

  // if there's a job id and we return the job
  if (jobId && data?.job) {
    return {
      fetchingJob: fetching,
      jobName: data.job.name,
      jobType: data.job.jobType.name,
      jobId,
    };
  }

  // Othewrwise we find the job based on the job type
  const selectedJob = data?.jobs?.find((job) => job.jobType.name === type);

  return {
    fetchingJob: fetching,
    jobName: selectedJob?.name,
    jobType: selectedJob?.jobType?.name,
    jobId: selectedJob?.id,
  };
};

export default useJobDetails;
