import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';

import Label from '../Label';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  spacer: {
    margin: '8px 10px',
    color: theme.palette.grey[500],
  },
  text: {
    marginTop: '8px',
  },
  adornment: {
    '& p': {
      lineHeight: '1.1875em',
    },
  },
}));

const IncomeField = ({ name, prefix, hintText, readOnly, ...props }) => {
  const classes = useStyles();

  return (
    <Field
      name={name}
      render={({ field, form: { errors, touched, submitCount } }) => {
        const errorMessage =
          ((touched && touched[field.name]) || submitCount > 0) &&
          errors &&
          errors[field.name];
        return (
          <FormControl error={!!errorMessage}>
            <Label {...field} {...props} />

            <div className={classes.wrapper}>
              <OutlinedInput
                classes={{
                  root: classes.text,
                }}
                startAdornment={
                  prefix && (
                    <InputAdornment
                      position="start"
                      className={classes.adornment}
                    >
                      {prefix}
                    </InputAdornment>
                  )
                }
                type="number"
                readOnly={readOnly}
                {...props}
                {...field}
              />

              <span className={classes.spacer}>/</span>

              <Field
                name={`${name}Period`}
                render={({ field: selectField }) => (
                  <FormControl>
                    <NativeSelect
                      input={
                        // eslint-disable-next-line
                        <OutlinedInput
                          className={classes.text}
                          {...selectField}
                        />
                      }
                      {...selectField}
                    >
                      <option value="year">Year</option>
                      <option value="month">Month</option>
                    </NativeSelect>
                  </FormControl>
                )}
              />
            </div>

            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}

            {hintText && <FormHelperText>{hintText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

IncomeField.propTypes = {
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  readOnly: PropTypes.bool,
  hintText: PropTypes.string,
};

IncomeField.defaultProps = {
  prefix: null,
  readOnly: false,
  hintText: null,
};

export default IncomeField;
