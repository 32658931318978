import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';

import Button from '../Button';
import FinmoLogo from '../FinmoLogo';

const useStyles = makeStyles(theme => ({
  appBar: {
    padding: '0',
    backgroundColor: theme.appBar.backgroundColor,
    boxShadow: 'none',
    borderBottom: `solid 1px ${theme.palette.grey[300]}`,
    color: theme.palette.common.black,
    height: theme.appBar.height,
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const LandingHeader = () => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Container className={classes.container}>
        <a
          href="https://finmo.co.uk/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.logo}
        >
          <FinmoLogo primary />
        </a>

        <Button
          className={classes.continueButton}
          href="https://calendly.com/jdrainey/learn-more-about-finmo"
          color="secondary"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book free consultation
        </Button>
      </Container>
    </AppBar>
  );
};

export default LandingHeader;
