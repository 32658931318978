import React from 'react';

const referrers = {
  'Collective Benefit': {
    id: 'CB',
    copy1: (
      <>
        Collective Benefits have partnered with Finmo on{' '}
        <strong>
          <em>The Great British Tax Off!</em>
        </strong>{' '}
        competition.
      </>
    ),
    copy2: 'Sign up today to get involved!',
  },
  'Collective Benefits': {
    id: 'CB',
    copy1: 'Collective Benefits have partnered with Finmo.',
  },
  gophr: {
    id: 'gophr',
  },
  UP: {
    id: 'UP',
  },
};

export default referrers;
