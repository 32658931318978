import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import JobDocumentField from '../JobDocumentField';

const JobDocumentFieldWrapper = ({ name, ...props }) => {
  return (
    <Field
      name={name}
      render={({ field, form: { errors, touched, submitCount, values } }) => {
        const errorMessage =
          ((touched && touched[field.name]) || submitCount > 0) &&
          errors &&
          errors[field.name];

        return (
          <JobDocumentField
            field={field}
            errorMessage={errorMessage}
            documentGroup={values?.documentGroup}
            {...props}
          />
        );
      }}
    />
  );
};

JobDocumentFieldWrapper.propTypes = {
  name: PropTypes.string.isRequired,
};

export default JobDocumentFieldWrapper;
