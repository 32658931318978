const getJobTypeDocuments = (job, documents) => {
  const jobDocuments = documents.filter(doc => doc.job?.id === job.id);

  if (job.jobType.name === 'earner') {
    const p45 = jobDocuments.find(
      doc =>
        doc.documentGroup === 'P45' ||
        doc.documentGroup === 'P60' ||
        doc.documentGroup === 'P45 or P60',
    );
    const p11d = jobDocuments.find(doc => doc.documentGroup === 'P11D');

    return {
      documents: [
        {
          id: '1',
          name: 'P45 or P60',
          complete: !!p45,
          file: p45,
          documentGroup: 'P45',
          jobId: job.id,
          info:
            'Provided by your employer at the end of the year or at the end of your employment.',
        },
        {
          id: '2',
          name: 'P11D',
          complete: !!p11d,
          file: p11d,
          documentGroup: 'P11D',
          jobId: job.id,
          info: 'Provided by your employer at the end of the year.',
        },
      ],
      complete: p45 && p11d,
      optional: false,
    };
  }

  if (job.jobType.name === 'investment') {
    const file = jobDocuments.find(
      doc => doc.documentGroup === 'Proof of Investment',
    );

    return {
      documents: [
        {
          id: '1',
          name: 'Proof of Investment',
          info: 'E.g. Invoice, Bill of Sale or End of Year Brokerage Statement',
          complete: !!file,
          documentGroup: 'Proof of Investment',
          jobId: job.id,
          file,
        },
      ],
      optional: false,
      complete: !!file,
    };
  }

  if (job.jobType.name === 'freelance') {
    const receipts = jobDocuments.filter(
      doc => doc.documentGroup === 'Receipt',
    );
    const invoices = jobDocuments.filter(
      doc => doc.documentGroup === 'Invoice',
    );

    return {
      documents: [
        {
          id: '1',
          name: 'Expenses',
          info: 'Receipts for any expense over £1,000',
          complete: !!(receipts && receipts.length),
          documentGroup: 'Receipt',
          jobId: job.id,
          files: receipts,
        },
        {
          id: '2',
          name: 'Income',
          info: 'Invoices or proof of income',
          complete: !!(invoices && invoices.length),
          documentGroup: 'Invoice',
          jobId: job.id,
          files: invoices,
        },
      ],
      complete:
        !!(receipts && receipts.length) && !!(invoices && invoices.length),
      optional: true,
    };
  }

  if (job.jobType.name === 'rental') {
    const receipts = jobDocuments.filter(
      doc => doc.documentGroup === 'Receipt',
    );
    const invoices = jobDocuments.filter(
      doc => doc.documentGroup === 'Invoice',
    );
    return {
      documents: [
        {
          id: '1',
          name: 'Expenses',
          info: 'Receipts for any expense over £1,000',
          complete: !!(receipts && receipts.length),
          documentGroup: 'Receipt',
          jobId: job.id,
          files: receipts,
        },
        {
          id: '2',
          name: 'Income',
          info: 'Signed rental agreement',
          complete: !!(invoices && invoices.length),
          documentGroup: 'Invoice',
          jobId: job.id,
          files: invoices,
        },
      ],
      complete:
        !!(receipts && receipts.length) && !!(invoices && invoices.length),
      optional: true,
    };
  }

  return { documents: [] };
};

const getDocumentValidation = (documents, jobs) => {
  if (!jobs) return [];

  const proofOfId = documents.find(doc => doc.documentGroup === 'Proof of ID');
  const proofOfAddress = documents.some(
    doc => doc.documentGroup === 'Proof of Address',
  );

  return [
    {
      id: '0',
      title: 'General documents',
      optional: false,
      complete: proofOfId && proofOfAddress,
      documents: [
        {
          id: '1',
          name: 'Proof of ID',
          documentGroup: 'Proof of ID',
          jobId: '',
          complete: !!proofOfId,
          file: proofOfId,
          info:
            'Need one of the following: Valid full UK photocard driving licence, Valid full UK passport, Original birth certificate, adoption certificate or valid non-UK passport.',
        },
        {
          id: '2',
          name: 'Proof of Address',
          documentGroup: 'Proof of Address',
          jobId: '',
          complete: !!proofOfAddress,
          file: proofOfAddress,
          info:
            'Need one of the following: UK mortgage statement (must be less than 12 months old), UK gas or electricity bill (must be less than 3 months old), UK phone bill (not mobile; must be less than 3 months old), UK water bill (must be less than 12 months old) or UK council tax bill (must be less than 12 months old).',
        },
      ],
    },
    ...jobs.reduce(
      (a, job) =>
        job.jobType.name === 'personal' || job.jobType.name === 'taxRelief'
          ? a
          : [
              ...a,
              {
                id: job.id,
                title: `${job.name} documents`,
                ...getJobTypeDocuments(job, documents),
              },
            ],
      [],
    ),
  ];
};
export default getDocumentValidation;
