import React from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import LayoutAuth from '../../components/LayoutAuth';
import Hero from '../../components/Hero';

const ConnectBankSuccess = () => {
  return (
    <LayoutAuth>
      <Hero
        title="Bank account connected"
        description="Your bank account has been connected successfully you can now close this window."
        icon={<CheckCircleIcon />}
        button={{
          label: 'Close window',
          color: 'secondary',
          onClick: () => {
            window.close();
          },
        }}
        dark
      />
    </LayoutAuth>
  );
};

export default ConnectBankSuccess;
