import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSpring, animated } from 'react-spring';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '../Button';
import Loader from '../Loader';

import backgroundImage from '../../assets/onboarding-bg-1.png';
import trustpilot from '../../assets/trustpilot-dark.png';
import trustpilotMobile from '../../assets/trustpilot-mobile-dark.png';

const useStyles = makeStyles((theme) => ({
  hero: {
    padding: '20px 0 60px',
  },
  marginTop: {
    paddingTop: '60px',
  },

  primary: {
    width: '100%',
    padding: '50px 20px 60px',
    margin: '-30px 0 -49px',
    [theme.breakpoints.up('sm')]: {
      padding: '50px 0 90px',
    },

    position: 'relative',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    '&:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.2)',
      zIndex: 0,
    },

    '& > *': {
      position: 'relative',
      zIndex: 0,
    },
  },
  marginBottom: {
    padding: '50px 0 70px',

    [theme.breakpoints.up('sm')]: {
      padding: '50px 0 100px',
    },
  },
  title: {
    paddingBottom: '0',
  },
  description: {
    color: theme.palette.grey[500],
    textAlign: 'center',
    margin: '16px auto 0',
    fontSize: '16px',
    maxWidth: 800,
  },
  white: {
    color: theme.palette.common.white,

    '& > a': {
      color: theme.palette.common.white,
    },

    '& > a:hover': {
      textDecoration: 'none',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
    '& svg': {
      width: '64px',
      fontSize: '64px',
      display: 'block',
      color: theme.palette.success.main,
    },
  },
  largeIcon: {
    marginBottom: '50px',

    '& svg': {
      width: '128px',
      fontSize: '128px',
      display: 'block',
    },
  },
  noNegativeMarginBotton: {
    marginBottom: '30px',
    paddingBottom: '50px',
  },

  trustpilotContainerMobile: {
    margin: '20px auto 0px',
    display: 'flex',
    height: '32px',

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  trustpilotImageMobile: {
    display: 'block',
    height: '32px',
    width: 'auto',
    margin: 'auto',

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  trustpilotContainer: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      margin: '20px auto 0px',
      display: 'flex',
      height: '23px',
    },
  },

  trustpilotImage: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      display: 'block',
      height: '23px',
      width: 'auto',
    },
  },
}));

const Hero = ({
  title,
  description,
  white,
  button,
  icon,
  marginTop,
  marginBottom,
  primary,
  fullWidth,
  renderTabs,
  fetching,
  largeIcon,
  noNegativeMarginBotton,
  showTrustPilot,
}) => {
  const classes = useStyles();

  const style = useSpring({
    opacity: fetching ? 0 : 1,
  });

  const ref = useRef(null);
  useEffect(() => {
    if (showTrustPilot && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [showTrustPilot]);

  return (
    <Grid
      container
      justify="center"
      className={clsx(classes.hero, {
        [classes.marginTop]: marginTop,
        [classes.marginBottom]: marginBottom,
        [classes.primary]: primary,
        [classes.noNegativeMarginBotton]: noNegativeMarginBotton,
      })}
    >
      <Grid item xs={12} sm={12} md={primary || fullWidth ? 12 : 7}>
        {fetching && <Loader />}
        <animated.div style={style}>
          {icon && (
            <div
              className={clsx(classes.icon, { [classes.largeIcon]: largeIcon })}
            >
              {icon}
            </div>
          )}

          <Typography
            variant="h1"
            component="h2"
            className={clsx(classes.title, { [classes.white]: white })}
            align="center"
          >
            {title}
          </Typography>

          <div
            className={clsx(classes.description, { [classes.white]: white })}
          >
            {description}
          </div>

          {button && (
            <div className={classes.actions}>
              <Button {...button}>{button.label}</Button>
            </div>
          )}

          {showTrustPilot && (
            <>
              <a
                href="https://uk.trustpilot.com/review/finmo.co.uk"
                className={classes.trustpilotContainerMobile}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.trustpilotImageMobile}
                  src={trustpilotMobile}
                  alt="Trustpilot"
                />
              </a>

              <a
                href="https://uk.trustpilot.com/review/finmo.co.uk"
                className={classes.trustpilotContainer}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.trustpilotImage}
                  src={trustpilot}
                  alt="Trustpilot"
                />
              </a>
            </>
          )}

          {renderTabs && renderTabs()}
        </animated.div>
      </Grid>
    </Grid>
  );
};

Hero.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  white: PropTypes.bool,
  button: PropTypes.shape({
    label: PropTypes.string,
  }),
  icon: PropTypes.node,
  marginTop: PropTypes.bool,
  marginBottom: PropTypes.bool,
  primary: PropTypes.bool,
  renderTabs: PropTypes.func,
  fetching: PropTypes.bool,
  largeIcon: PropTypes.bool,
  fullWidth: PropTypes.bool,
  noNegativeMarginBotton: PropTypes.bool,
  showTrustPilot: PropTypes.bool,
};

Hero.defaultProps = {
  white: false,
  button: null,
  icon: null,
  marginTop: false,
  primary: false,
  marginBottom: false,
  renderTabs: undefined,
  fetching: false,
  title: null,
  largeIcon: false,
  fullWidth: false,
  noNegativeMarginBotton: false,
  showTrustPilot: false,
};

export default Hero;
