import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import BackupIcon from '@material-ui/icons/Backup';

import Label from '../Label';

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.grey[100],
    padding: 50,
    position: 'relative',
    textAlign: 'center',
    color: theme.palette.grey[500],
    overflow: 'hidden',
  },
  input: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    opacity: 0,
    cursor: 'pointer',
  },
  compact: {
    padding: 10,
  },
}));

const FileField = ({ name, hintText, disabled, label, compact, ...props }) => {
  const classes = useStyles();

  return (
    <Field
      name={name}
      render={({
        field,
        form: { errors, touched, submitCount, setFieldValue },
      }) => {
        if (disabled) return false;

        const errorMessage =
          ((touched && touched[field.name]) || submitCount > 0) &&
          errors &&
          errors[field.name];

        const handleChange = ({
          target: {
            files: [file],
          },
        }) => {
          setFieldValue(name, file);
        };

        const handleRemove = () => {
          setFieldValue(name, '');
        };

        const renderContent = () => {
          if (field.value?.file) {
            return (
              <>
                <BackupIcon />

                <Typography type="body" gutterBottom>
                  {`File selected: ${field.value.file}`}
                </Typography>

                <Button
                  href={field.value.uri}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View file
                </Button>
                <Button onClick={handleRemove}>Remove</Button>
              </>
            );
          }

          if (field.value?.name) {
            return (
              <>
                <BackupIcon />

                <Typography type="body" gutterBottom>
                  {`File selected: ${field.value?.name}`}
                </Typography>

                <Button onClick={handleRemove}>Remove</Button>
              </>
            );
          }

          return (
            <>
              <BackupIcon />

              <Typography type="body">
                Drag and drop file here or click to select.
              </Typography>
            </>
          );
        };

        return (
          <FormControl fullWidth error={!!errorMessage}>
            {label && (
              <Label marginBottom label={label} {...field} {...props} />
            )}

            <div
              className={clsx(classes.wrapper, { [classes.compact]: compact })}
            >
              <input
                className={classes.input}
                name={name}
                type="file"
                error={!!errorMessage}
                {...field}
                value={field.value ? field.value.filename : ''}
                onChange={handleChange}
              />

              {renderContent()}
            </div>

            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}

            {hintText && <FormHelperText>{hintText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

FileField.propTypes = {
  name: PropTypes.string.isRequired,
  hintText: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  compact: PropTypes.bool,
};

FileField.defaultProps = {
  disabled: false,
  hintText: null,
  label: null,
  compact: false,
};

export default FileField;
