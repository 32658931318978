import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import backgroundImage from '../../assets/blue-background.png';

import { ReactComponent as FCALogo } from '../../assets/fca-logo.svg';

const useStyles = makeStyles(theme => ({
  hero: {
    backgroundSize: 'cover',
    color: theme.palette.common.white,
    backgroundImage: `url(${backgroundImage})`,

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },

  container: {
    [theme.breakpoints.up('md')]: {
      flex: '1 0 100%',
      width: '100%',
    },
  },

  inner: {
    padding: '30px 0',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      width: '100%',
      padding: '50px 0 0 0',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  },

  content: {
    padding: '30px 0',
    margin: '0 auto',

    [theme.breakpoints.up('md')]: {
      flex: '0 0 50%',
      width: '50%',
      padding: '50px 50px 100px 0',
    },
  },

  title: {
    paddingBottom: '0',
    fontWeight: '900',
    fontSize: '45px',
    marginBottom: '40px',

    [theme.breakpoints.up('md')]: {
      fontSize: '62px',
      marginBottom: '30px',
    },
  },

  description: {
    margin: '16px 0 0',
    fontSize: '18px',
    lineHeight: '28px',
  },

  form: {
    maxWidth: '480px',
    margin: '0 auto',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      flex: '0 0 50%',
      width: '50%',
      position: 'relative',
      zIndex: 5,
      alignSelf: 'flex-end',
    },
  },

  bar: {
    backgroundColor: theme.appBar.backgroundColor,

    [theme.breakpoints.up('md')]: {
      marginTop: '-50px',
      position: 'relative',
      zIndex: 4,
      flex: '0 0  100%',
    },
  },

  barContainer: {
    display: 'flex',
    padding: '10px 0',
    color: theme.palette.grey[500],
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
    },

    '& strong': {
      color: theme.palette.grey[800],
    },
  },

  logo: {
    height: 40,
    marginRight: 30,
    fill: theme.palette.primary.main,
  },
}));

const LandingHero = ({ title, description, renderForm }) => {
  const classes = useStyles();

  return (
    <div className={classes.hero}>
      <Container className={classes.container}>
        <div className={classes.inner}>
          <div className={classes.content}>
            <Typography variant="h1" component="h1" className={classes.title}>
              {title}
            </Typography>

            <div className={classes.description}>{description}</div>
          </div>

          <div className={classes.form}>{renderForm()}</div>
        </div>
      </Container>

      <div className={classes.bar}>
        <Container>
          <div className={classes.barContainer}>
            <FCALogo className={classes.logo} />

            <p>
              <strong>Finmo is regulated & authorised by the FCA.</strong>
              <br /> Bank level security and encryption is used keeps your data
              safe.
            </p>
          </div>
        </Container>
      </div>
    </div>
  );
};

LandingHero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  renderForm: PropTypes.bool.isRequired,
};

export default LandingHero;
