import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Section from '../../components/Section';

import LayoutLanding from '../../components/LayoutLanding';
import RegisterForm from '../../components/RegisterForm';
import LandingHero from '../../components/LandingHero';
import LandingTitle from '../../components/LandingTitle';
import TextContent from '../../components/TextContent';

import { registerFormLanding } from '../../config/FormFields';
import HowItWorks from '../../components/HowItWorks/HowItWorks';
import FinmoIsFor from '../../components/FinmoIsFor/FinmoIsFor';
import WhyUseFinmo from '../../components/WhyUseFinmo/WhyUseFinmo';

const LandingExpenses = () => {
  const renderRegisterForm = () => (
    <RegisterForm
      noMobilePadding={false}
      redirectUrl="/onboarding/plans"
      fields={registerFormLanding}
      noCard={false}
      header="Get started for free"
      subheader="Create your Finmo account to start your Self Assessment"
      centerHeader
      id="getStarted"
    />
  );

  return (
    <LayoutLanding title="Finmo Accountants are Experts on HMRC’s Self Assessment Expense Rules.">
      <LandingHero
        title="Finmo Accountants are Experts on HMRC’s Self Assessment Expense Rules."
        description="Sometimes keeping it simple may not be the best option. While simplified expenses work great if you work from an office, it may help to consider other options. Keeping track of your expenses and knowing which to claim in full, which to claim in part and what not to claim at all can be confusing and has wide ranging impacts. Let Finmo help."
        renderForm={renderRegisterForm}
      />

      <Section>
        <LandingTitle>Optimise your expenses</LandingTitle>
        <Typography align="center" gutterBottom>
          Finmo allows your to accurately calculate your expenses so you pay
          less tax.
        </Typography>

        <Grid container spacing={5}>
          <Grid item sm={6}>
            <TextContent pad>
              <h3>Working from home</h3>

              <p>
                Flexible working and working from home can be the most
                liberating and loneliest time of a career. However, working from
                home can provide some enormous tax benefits if done correctly
                and within the rules and guidance from HMRC. Finmo Accountants
                are well versed in HMRC’s rules and work with you to help find
                the right method.
              </p>
            </TextContent>
          </Grid>

          <Grid item sm={6}>
            <TextContent>
              <h3>Keeping track of your expenses</h3>

              <p>
                As a sole-trader, keeping track of your daily business expenses
                can seem time-consuming and un-needed. A few quid here for
                travel, a few there for lunch and while each individual
                transaction may not seem like a lot, added up, you may be able
                to save hundreds.
              </p>
            </TextContent>
          </Grid>
        </Grid>
      </Section>

      <FinmoIsFor grey />

      <HowItWorks />

      <WhyUseFinmo grey />
    </LayoutLanding>
  );
};

export default LandingExpenses;
