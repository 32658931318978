import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import LayoutAuth from '../../components/LayoutAuth';
import RegisterForm from '../../components/RegisterForm';
import OnboardingHeader from '../../components/OnboardingHeader';
import OnboardingHint from '../../components/OnboardingHint';

import { ReactComponent as TaxCircle } from '../../assets/taxCircle.svg';
import { ReactComponent as TagCircle } from '../../assets/tagCircle.svg';
import { ReactComponent as LockCircle } from '../../assets/lockCircle.svg';

import referrers from '../../config/referrers';

const Register = ({ referralCode, referrerName }) => {
  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'select', {
        send_to: 'UA-151517575-1',
        event_category: 'registration',
        event_label: 'register',
      });
    }
  }, []);

  const renderSideContent = () => {
    return (
      <OnboardingHint
        header="Your Self Assessment sorted in minutes"
        list={[
          {
            id: '1',
            icon: <LockCircle />,
            content:
              'Securely import your transactions and automatically manage your business income and expenses.',
          },
          {
            id: '2',
            icon: <TagCircle />,
            content:
              'Tag your expenses and see real time tax estimates throughout the year. Never again worry about not knowing how much you will owe at the end of the year.',
          },
          {
            id: '3',
            icon: <TaxCircle />,
            content:
              'Provide Tax Relief, PAYE and other tax-related information, and submit to one of our fully qualified and licensed accountants.',
          },
        ]}
      />
    );
  };

  const { id, copy1, copy2 } = referrers[referrerName] || {};

  return (
    <LayoutAuth
      renderSideContent={renderSideContent}
      referral={id}
      currentStep={2}
      completedStep={1}
      totalSteps={7}
    >
      <OnboardingHeader
        header={copy1 || 'Start by creating your free acount'}
        subheader={
          copy2 || "Let's get you on the road to tax and finance confidence"
        }
      />

      <RegisterForm
        redirectUrl="/onboarding/password"
        referralCode={referralCode}
      />
    </LayoutAuth>
  );
};

Register.propTypes = {
  referralCode: PropTypes.string,
  referrerName: PropTypes.string,
};

Register.defaultProps = {
  referralCode: null,
  referrerName: null,
};

export default Register;
