/**
 * Utility function to format business data
 *
 * @param {object} data - Object
 * @return {array} Array of values and category pairs
 *
 */

import { baseColors } from '../theme/base';

const formatBusinessOverviewData = (data) => {
  if (!data || !data.taxCalculation) return [];

  return [
    {
      id: 'turnOver',
      category: 'Turnover (Gross Income)',
      value: data.taxCalculation.actualIncomeToDate,
    },
    {
      id: 'expenses',
      category: 'Expenses',
      value: data.taxCalculation.actualExpenseToDate,
    },
    {
      id: 'taxableIncome',
      category: 'Taxable Income',
      value: Math.max(
        0,
        data.taxCalculation.actualIncomeToDate -
          data.taxCalculation.actualExpenseToDate,
      ),
    },
    {
      id: 'totalTax',
      category: 'Estimated Total Tax',
      value: data.taxCalculation.fullYearEstimate,
      style: {
        backgroundColor: baseColors.lightPink,
        fontWeight: 'bold',
      },
    },
    {
      id: 'incomeTax',
      category: 'Income Tax',
      value: data.taxCalculation.incomeTax,
      indent: true,
    },
    {
      id: 'nationalInsuranceTax',
      category: 'National Insurance',
      value: data.taxCalculation.nationalInsuranceTax,
      indent: true,
    },
    {
      id: 'vat',
      category: 'VAT',
      value: data.taxCalculation.vat,
      indent: true,
    },
  ];
};

export default formatBusinessOverviewData;
