import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import LayoutAuth from '../../components/LayoutAuth';
import Form from '../../components/Form';
import OnboardingHeader from '../../components/OnboardingHeader';

import { changePasswordForm } from '../../config/FormFields';
import { validate, requiredString } from '../../utils/validate';

import { CHANGE_FORGOTTEN_PASSWORD } from '../../graph/auth';

const resetFormValidationSchema = validate({
  email: requiredString,
  newPassword: requiredString,
});

const resetFormInitialValues = {
  email: '',
  newPassword: '',
};

const ChangePassword = ({ token }) => {
  const [changeComplete, setChangeComplete] = useState(false);

  const [handleChangePassword, { loading, error }] = useMutation(
    CHANGE_FORGOTTEN_PASSWORD,
    {
      onCompleted({ changeForgottenPassword }) {
        if (
          !changeForgottenPassword ||
          !changeForgottenPassword.passwordChanged
        )
          return;

        setChangeComplete(true);
      },
    },
  );

  const changePasswordTransformer = ({ __typename, ...data }) => {
    return {
      variables: {
        ...data,
        activationCode: token,
      },
    };
  };

  const renderContent = () => {
    if (changeComplete) {
      return (
        <OnboardingHeader
          header="Password changed successfully"
          subheader="Please log in by clicking the link below."
          marginBottom
        />
      );
    }

    return (
      <>
        <OnboardingHeader
          header="Change Password"
          subheader="Please enter your new password and the email you registered with."
        />

        <Form
          errorMessage="Sorry there has been a problem changing your password"
          noCard
          fields={changePasswordForm}
          loading={loading}
          error={error}
          onSubmit={handleChangePassword}
          dataTransformer={changePasswordTransformer}
          validationSchema={resetFormValidationSchema}
          initialValues={resetFormInitialValues}
          noActionBar
          maxWidthButton
          narrow
          button={{
            label: 'Change password',
            color: 'secondary',
            variant: 'contained',
            fullWidth: true,
          }}
        />
      </>
    );
  };

  return (
    <LayoutAuth>
      {renderContent()}

      <Typography align="center" gutterBottom color="primary">
        <Link style={{ color: 'inherit' }} to="/">
          Back to login
        </Link>
      </Typography>
    </LayoutAuth>
  );
};

ChangePassword.propTypes = {
  token: PropTypes.string,
};

ChangePassword.defaultProps = {
  token: null,
};

export default ChangePassword;
