import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '../Button';

const useStyles = makeStyles(theme => ({
  section: {
    backgroundColor: theme.palette.common.white,
    padding: '30px 10px',

    [theme.breakpoints.up('md')]: {
      padding: '50px 0',
    },

    '& p': {
      color: theme.palette.grey[500],
    },
  },

  grey: {
    backgroundColor: '#fafafa',
  },

  hasBottomImage: {
    paddingBottom: 0,
  },

  image: {
    width: '100%',
    display: 'block',
    maxWidth: '1000px',
    margin: '30px auto 0',
  },

  button: {
    margin: '30px auto 0',
    display: 'block',
    width: '200px',
    textAlign: 'center',
  },

  hasBackgroundImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

const Section = ({
  children,
  grey,
  bottomImage,
  bottomImageAlt,
  action,
  backgroundImage,
}) => {
  const classes = useStyles();

  return (
    <section
      className={clsx(classes.section, {
        [classes.grey]: grey,
        [classes.hasBottomImage]: !!bottomImage,
        [classes.hasBackgroundImage]: !!backgroundImage,
      })}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <Container>
        {children}

        {!!action && (
          <Button className={classes.button} {...action}>
            {action.label || 'Get Started'}
          </Button>
        )}

        {!!bottomImage && (
          <img
            className={classes.image}
            src={bottomImage}
            alt={bottomImageAlt}
          />
        )}
      </Container>
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  grey: PropTypes.bool,
  bottomImage: PropTypes.string,
  bottomImageAlt: PropTypes.string,
  action: PropTypes.shape({
    label: PropTypes.string,
  }),
  backgroundImage: PropTypes.string,
};

Section.defaultProps = {
  grey: false,
  bottomImage: null,
  bottomImageAlt: null,
  action: null,
  backgroundImage: null,
};

export default Section;
