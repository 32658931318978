import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import { useQuery } from '@apollo/react-hooks';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import AuthHeader from '../AuthHeader';
import AuthFooter from '../AuthFooter';
import Loader from '../Loader';

import { GET_CURRENT_USER } from '../../graph/auth';

const useStyles = makeStyles((theme) => ({
  authContainer: {
    paddingTop: theme.appBar.height,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  mainSection: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    padding: '30px 30px 70px',
    display: 'flex',
    flex: '1 0 auto',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      padding: '50px 20px',
    },
  },
  asideSection: {
    position: 'fixed',
    right: 0,
    top: theme.appBar.height,
    bottom: 0,
    transform: 'translateX(100%)',
    width: '100%',
    maxWidth: 345,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
    zIndex: theme.zIndex.drawer,
    borderLeft: `solid 1px ${theme.palette.grey[300]}`,

    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.short,
    }),
  },

  showSideContent: {
    transform: 'translateX(0%)',

    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.short,
    }),
  },

  contentContainer: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    margin: 'auto',

    [theme.breakpoints.up('md')]: {
      maxWidth: '1200px',
    },
  },

  asideInner: {
    position: 'relative',
  },

  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    padding: 0,
    width: 20,
    height: 20,
    display: 'block',

    '& svg': {
      width: 20,
      height: 20,
      display: 'block',
    },

    '&:after': {
      content: "''",
      position: 'absolute',
      top: -10,
      left: -10,
      bottom: -10,
      right: -10,
    },
  },
}));

const LayoutAuth = ({
  children,
  renderSideContent,
  fetching,
  referral,
  showSideContent,
  onCloseSideContent,
  ...props
}) => {
  const classes = useStyles();

  const { data } = useQuery(GET_CURRENT_USER, { fetchPolicy: 'cache-only' });

  const style = useSpring({
    opacity: fetching ? 0 : 1,
    transform: fetching ? 'translate(0, 40px)' : 'translate(0, 1px)',
  });

  return (
    <div className={classes.authContainer}>
      <AuthHeader
        referral={referral || data?.user?.referralPartner?.name}
        {...props}
      />

      <main className={classes.mainSection}>
        {fetching && <Loader />}

        <animated.div className={classes.contentContainer} style={style}>
          {children}
        </animated.div>
      </main>

      {renderSideContent && (
        <aside
          className={clsx(classes.asideSection, {
            [classes.showSideContent]: showSideContent,
          })}
        >
          <div className={classes.asideInner}>
            {renderSideContent && renderSideContent()}

            {!!onCloseSideContent && (
              <button
                className={classes.closeButton}
                onClick={onCloseSideContent}
                type="button"
              >
                <CloseIcon />
              </button>
            )}
          </div>
        </aside>
      )}

      <AuthFooter />
    </div>
  );
};

LayoutAuth.propTypes = {
  children: PropTypes.node.isRequired,
  renderSideContent: PropTypes.func,
  fetching: PropTypes.bool,
  referral: PropTypes.string,
  showSideContent: PropTypes.bool,
  onCloseSideContent: PropTypes.func,
};

LayoutAuth.defaultProps = {
  renderSideContent: undefined,
  fetching: false,
  referral: null,
  showSideContent: false,
  onCloseSideContent: undefined,
};

export default LayoutAuth;
