import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Section from '../Section';
import TextContent from '../TextContent';
import LandingTitle from '../LandingTitle';

const FinmoIsFor = ({ ...props }) => {
  return (
    <Section
      action={{ href: '#getStarted', color: 'secondary', variant: 'contained' }}
      {...props}
    >
      <LandingTitle>Finmo is for...</LandingTitle>
      <Typography align="center">
        …anyone who needs to file a Self Assessment Tax Return.
      </Typography>

      <Grid container spacing={5}>
        <Grid item sm={3}>
          <TextContent noMargin center>
            <h3>Sole Traders</h3>
            <h3>Investors</h3>
          </TextContent>
        </Grid>

        <Grid item sm={3}>
          <TextContent noMargin center>
            <h3>Multi-job</h3>
            <h3>Landlords</h3>
          </TextContent>
        </Grid>

        <Grid item sm={3}>
          <TextContent noMargin center>
            <h3>Freelancers</h3>
            <h3>Sub-contractors</h3>
          </TextContent>
        </Grid>

        <Grid item sm={3}>
          <TextContent noMargin center>
            <h3>First time tax filers</h3>
            <h3>PAYE plus side income</h3>
          </TextContent>
        </Grid>
      </Grid>
    </Section>
  );
};

export default FinmoIsFor;
