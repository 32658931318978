import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import LinearProgress from '@material-ui/core/LinearProgress';

import FinmoLogo from '../FinmoLogo';

import { ReactComponent as CollectiveBenefitsLogo } from '../../assets/collectivebenefitslogo.svg';

import gophrLogo from '../../assets/gophr-logo.png';
import trustpilot from '../../assets/trustpilot.png';
import trustpilotMobile from '../../assets/trustpilot-mobile.png';
import upLogo from '../../assets/up-logo.webp';

const useStyles = makeStyles((theme) => ({
  appBar: {
    padding: '0 30px',
    backgroundColor: theme.appBar.backgroundColor,
    boxShadow: 'none',
    borderBottom: `solid 1px ${theme.palette.grey[300]}`,
    color: theme.palette.common.black,
    height: theme.appBar.height,
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  logo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  referral: {
    [theme.breakpoints.down('xs')]: {
      transform: 'translateY(-50%) scale(0.9)',
      width: '90%',
      left: 0,
    },
  },

  stepText: {
    display: 'none',
    color: theme.palette.grey[500],

    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  trustpilotContainerMobile: {
    marginLeft: 'auto',
    display: 'flex',
    height: '32px',

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  trustpilotImageMobile: {
    display: 'block',
    height: '32px',
    width: 'auto',

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  trustpilotContainer: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      display: 'flex',
      height: '23px',
    },
  },

  trustpilotImage: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'block',
      height: '23px',
      width: 'auto',
    },
  },

  referralLogo: {
    marginLeft: '10px',
    paddingLeft: '10px',
    borderLeft: 'solid 1px rgba(0, 0, 0, 0.05)',
    height: '35px',
  },

  referralLogoImage: {
    marginLeft: '10px',
    paddingLeft: '14px',
    borderLeft: 'solid 1px rgba(0, 0, 0, 0.05)',
    height: '32px',
  },

  progress: {
    position: 'fixed',
    top: theme.appBar.height,
    right: 0,
    left: 0,
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: theme.palette.grey[200],
  },
}));

const AuthHeader = ({
  currentStep,
  completedStep,
  totalSteps,
  referral,
  isLoggedIn,
}) => {
  const classes = useStyles();

  // Check if the user agent is the mobile app web view
  if (navigator.userAgent === 'WEBVIEW') {
    return false;
  }

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        {!!(currentStep && totalSteps) && (
          <span className={classes.stepText}>
            {`Getting started: Step ${currentStep}/${totalSteps}`}
          </span>
        )}

        <div
          className={clsx(classes.logo, {
            [classes.referral]: referral === 'CB',
          })}
        >
          {isLoggedIn ? (
            <Link to="/">
              <FinmoLogo primary />
            </Link>
          ) : (
            <a
              href="https://finmo.co.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FinmoLogo primary />
            </a>
          )}

          {referral === 'CB' && (
            <CollectiveBenefitsLogo className={classes.referralLogo} />
          )}

          {referral === 'gophr' && (
            <img
              src={gophrLogo}
              alt="Gophr"
              className={classes.referralLogoImage}
            />
          )}

          {referral === 'UP' && (
            <img
              src={upLogo}
              alt="Underpinned"
              className={classes.referralLogoImage}
            />
          )}
        </div>

        <span className={classes.trustpilotContainerMobile}>
          <img
            className={classes.trustpilotImageMobile}
            src={trustpilotMobile}
            alt="Trustpilot"
          />
        </span>

        <span className={classes.trustpilotContainer}>
          <img
            className={classes.trustpilotImage}
            src={trustpilot}
            alt="Trustpilot"
          />
        </span>
      </AppBar>

      {!!totalSteps && (
        <LinearProgress
          className={classes.progress}
          variant="determinate"
          value={(completedStep / totalSteps) * 100}
          color="primary"
        />
      )}
    </>
  );
};

AuthHeader.propTypes = {
  currentStep: PropTypes.number,
  completedStep: PropTypes.number,
  totalSteps: PropTypes.number,
  referral: PropTypes.string,
  isLoggedIn: PropTypes.bool,
};

AuthHeader.defaultProps = {
  currentStep: null,
  completedStep: 0,
  totalSteps: null,
  referral: null,
  isLoggedIn: false,
};

export default AuthHeader;
