import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import LandingHeader from '../LandingHeader';
import Loader from '../Loader';
import LandingFooter from '../LandingFooter';

const useStyles = makeStyles(theme => ({
  authContainer: {
    paddingTop: theme.appBar.height,
  },
  mainSection: {
    position: 'relative',
  },

  contentContainer: {
    position: 'relative',
    zIndex: 1,
  },

  noHeader: {
    paddingTop: 0,
  },
}));

const LayoutLanding = ({ children, fetching, title, noHeader, ...props }) => {
  const classes = useStyles();

  const style = useSpring({
    opacity: fetching ? 0 : 1,
    transform: fetching ? 'translate(0, 40px)' : 'translate(0, 1px)',
  });

  return (
    <div
      className={clsx(classes.authContainer, { [classes.noHeader]: noHeader })}
    >
      <Helmet>
        <title>{`Finmo - ${title}`}</title>
      </Helmet>

      {!noHeader && <LandingHeader {...props} />}

      <main className={classes.mainSection}>
        {fetching && <Loader />}

        <animated.div className={classes.contentContainer} style={style}>
          {children}

          <LandingFooter {...props} />
        </animated.div>
      </main>
    </div>
  );
};

LayoutLanding.propTypes = {
  children: PropTypes.node.isRequired,
  fetching: PropTypes.bool,
  title: PropTypes.string.isRequired,
  noHeader: PropTypes.bool,
};

LayoutLanding.defaultProps = {
  fetching: false,
  noHeader: false,
};

export default LayoutLanding;
