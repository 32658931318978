import dayjs from 'dayjs';

const getStartDatesFromScope = ({ year }) => {
  return {
    dateTo: dayjs(year).startOf('year').add(1, 'year').month(3).date(6),
    dateFrom: dayjs(year).endOf('year').month(3).date(6),
  };
};

export default getStartDatesFromScope;
