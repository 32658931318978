import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: 600,
    margin: '10px auto',

    [theme.breakpoints.up('md')]: {
      margin: '30px auto',
    },

    '& h1, & h2, & h3, & h4, & h5, & h6': {
      color: theme.palette.grey[900],
    },

    '& a': {
      color: theme.palette.secondary.main,
    },

    '& .MuiAvatar-root': {
      fontSize: '16px',
      width: '30px',
      height: '30px',
      marginRight: '20px',
      backgroundColor: theme.palette.secondary.main,
    },
  },

  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const TextContent = ({ children, center, ...props }) => {
  const classes = useStyles();

  return (
    <Typography
      className={clsx(classes.content, ([classes.center]: center))}
      align={center ? 'center' : 'left'}
      {...props}
    >
      {children}
    </Typography>
  );
};

TextContent.propTypes = {
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
};

TextContent.defaultProps = {
  center: false,
};

export default TextContent;
