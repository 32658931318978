import React from 'react';

import LayoutLanding from '../../components/LayoutLanding';
import RegisterForm from '../../components/RegisterForm';
import LandingHero from '../../components/LandingHero';

import { registerFormLanding } from '../../config/FormFields';
import HowItWorks from '../../components/HowItWorks/HowItWorks';
import FinmoIsFor from '../../components/FinmoIsFor/FinmoIsFor';
import WhyUseFinmo from '../../components/WhyUseFinmo/WhyUseFinmo';

const LandingTechnology = () => {
  const renderRegisterForm = () => (
    <RegisterForm
      noMobilePadding={false}
      redirectUrl="/onboarding/plans"
      fields={registerFormLanding}
      noCard={false}
      header="Get started for free"
      subheader="Create your Finmo account to start your Self Assessment"
      centerHeader
      id="getStarted"
    />
  );

  return (
    <LayoutLanding title="Stress free Self Assessment with the Finmo tax app.">
      <LandingHero
        title="Stress free Self Assessment with the Finmo tax app."
        description="The Finmo tax app makes going through your business transactions at the end of the year simple and stress free. Through innovative features like Rules and Reconcile, never missing an expenses is easy. Finmo connects Members with a Finmo Accountant to sort their Self Assessment at the end of the year for £159 + VAT."
        renderForm={renderRegisterForm}
      />

      <HowItWorks />

      <FinmoIsFor grey />

      <WhyUseFinmo />
    </LayoutLanding>
  );
};

export default LandingTechnology;
