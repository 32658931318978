import isArrayWithLenth from './isArrayWithLenth';

const sortTableData = (data, key, order, cols) => {
  if (!isArrayWithLenth(data)) return [];

  if (!key) return data;

  const col = cols.find((val) => val.id === key);

  const sortableData = data.map((item) => item);

  const sortedData = sortableData.sort((a, b) => {
    const aValue = col?.sortValueTransformer
      ? col?.sortValueTransformer(a[key], a)
      : a[key];
    const bValue = col?.sortValueTransformer
      ? col?.sortValueTransformer(b[key], b)
      : b[key];

    if (order === 'DESC' && aValue > bValue) {
      return -1;
    }

    if (order === 'DESC' && aValue < bValue) {
      return 1;
    }

    if (order === 'ASC' && aValue > bValue) {
      return 1;
    }

    if (order === 'ASC' && aValue < bValue) {
      return -1;
    }

    return 0;
  });

  return sortedData;
};

export default sortTableData;
