/**
 * Utility function to format income data
 *
 * @param {object} data - Object
 * @return {array} Array of values and category pairs
 *
 */

const formatIncomeTypesData = data => {
  if (!data || !data.jobSummary) return [];

  return data.jobSummary.map(({ taggedAmount, job }) => ({
    value: parseFloat(taggedAmount),
    jobType: job?.jobType?.name,
    id: job?.name,
    name: job?.name,
  }));
};

export default formatIncomeTypesData;
