const getTimeString = (timeInSeconds: number, fullString?: boolean) => {
  if (timeInSeconds < 60) {
    return `${timeInSeconds}${fullString ? ' seconds' : 's'}`;
  }
  const timeInMinutes = Math.floor(timeInSeconds / 60);
  if (timeInMinutes < 100) {
    return `${timeInMinutes}${fullString ? ' minutes' : 'm'}`;
  }
  const timeInHours = Math.floor(timeInMinutes / 60);
  return `${timeInHours}${fullString ? ' hours' : 'h'}`;
};

export default getTimeString;
