/**
 * Utility function to convert an array of answers into a form value object
 *
 * @param {array} array - Array of keys
 * @return {object} Form values
 *
 */

const answerArrayToValueOjbect = array =>
  array &&
  array.reduce(
    (a, { identifier, value }) => ({ ...a, [identifier]: value }),
    {},
  );

export default answerArrayToValueOjbect;
