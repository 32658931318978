import { createTheme } from '@material-ui/core/styles';

// Configure all material ui styles here and add custom overrides
// (One off style changes can be made within the component)
// Default theme can be found here: https://material-ui.com/customization/default-theme/

const baseColors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  blushPink: 'rgb(250, 138, 147)',
  orangeyRed: 'rgb(255, 59, 48)',
  darkishPink: 'rgb(232, 49, 135)',
  lightPink: 'rgba(232, 49, 135, 0.1)',
  grapePurpleLight: 'rgba(45, 25, 92, 0.1)',
  grapePurple: 'rgb(45, 25, 92)',
  grapePurpleGradient:
    'linear-gradient(to top right, rgba(45, 25, 92, 1), rgba(45, 25, 92, 0.8))',
  lightPeach: 'rgb(219, 206, 206)',
  dusk: 'rgb(87, 71, 124)',
  goldenRod: 'rgb(250, 202, 12)',
  greenishTeal: 'rgb(50, 182, 122)',
  tradewind: 'rgb(97, 191, 173)',
  darkishPinkTwo: 'rgb(234, 81, 93)',
  turquoiseBlue: 'rgb(11, 188, 214)',
  yellowOrange: 'rgb(238, 178, 0)',
  cloudyBlue: 'rgb(198, 202, 223)',
  royalBlue: 'rgb(17, 35, 120)',
  royalBlueAlpha: 'rgba(17, 35, 120, 0.24)',
  cobalt: 'rgb(26, 43, 125)',
  cobaltAlpha: 'rgba(26, 43, 125, 0.1)',
  veryLightBlue: 'rgb(236, 240, 255)',
  iceBlue: 'rgb(247, 249, 255)',
  lightSeperator: 'rgba(255, 255, 255, .12)',
  hairline: 'rgba(0, 0, 0, .3)',
  hairlineLight: 'rgba(255, 255, 255, 1)',
  shadow: 'rgb(32, 41, 90)',
  silverAlpha: 'rgba(224, 224, 227, 0.5)',
  purpleHeart: 'rgb(113, 50, 169)',
  peachyPink: 'rgb(255, 139, 139)',
  lightGrey: '#F8FAFB',
};

const tagColors = {
  default: baseColors.white,
  defaultText: baseColors.royalBlue,
  defaultBorder: baseColors.cobaltAlpha,
  selected: baseColors.iceBlue,
  freelance: baseColors.darkishPink,
  rental: baseColors.turquoiseBlue,
  interest: baseColors.greenishTeal,
  investment: baseColors.blushPink,
  earner: baseColors.yellowOrange,
  business: baseColors.tradewind,
  transfer: baseColors.greenishTeal,
  taxrelief: baseColors.greenishTeal,
  taxRelief: baseColors.greenishTeal,
  personal: baseColors.purpleHeart,
  untagged: baseColors.lightGrey,
};

const vars = {
  appBarHeight: 66,
};

const baseTheme = createTheme({
  drawer: {
    width: 240,
    widthSmall: 72,
  },
  appBar: {
    height: vars.appBarHeight,
    backgroundColor: '#FAFAFA',
  },
  footer: {
    height: 57,
  },
  hero: {
    height: 294,
  },
  palette: {
    primary: {
      main: baseColors.grapePurple,
      gradient: baseColors.grapePurpleGradient,
    },
    secondary: {
      main: baseColors.darkishPink,
      light: 'rgba(232, 49, 135, 0.1)',
      lighter: 'rgba(232, 49, 135, 0.06)',
    },
    success: {
      main: '#4CAF50',
    },
    blue: {
      main: '#00BCD4',
      dark: '#0097AA',
    },
    lightGrey: {
      main: baseColors.lightGrey,
    },
  },
  typography: {
    h1: {
      fontSize: '2.6rem',
      main: baseColors.grapePurple,
      light: '#6236FF',
    },
  },
});

export { baseColors, vars, tagColors };
export default baseTheme;
