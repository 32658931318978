import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';

import Label from '../Label';

const useStyles = makeStyles({
  field: {
    marginTop: '8px',
  },
  small: {
    height: '36px',
    margin: 0,
  },
});

const SelectField = ({
  options,
  name,
  hintText,
  readOnly,
  disabled,
  small,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Field
      name={name}
      render={({ field, form: { errors, touched, submitCount } }) => {
        const errorMessage =
          ((touched && touched[field.name]) || submitCount > 0) &&
          errors &&
          errors[field.name];

        return (
          <FormControl fullWidth error={!!errorMessage}>
            <Label {...field} {...props} />

            <NativeSelect
              input={<OutlinedInput {...field} />}
              className={clsx(classes.field, { [classes.small]: small })}
              error={!!errorMessage}
              value={field.value || ''}
              disabled={disabled}
              readOnly={readOnly}
              {...field}
            >
              <option value="">Select</option>
              {options &&
                options.map(
                  ({
                    id: optionId,
                    value,
                    label: optionLabel,
                    name: optionName,
                  }) => (
                    <option key={optionId} value={value || optionId}>
                      {optionLabel || optionName}
                    </option>
                  ),
                )}
            </NativeSelect>

            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}

            {hintText && <FormHelperText>{hintText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

SelectField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hintText: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
};

SelectField.defaultProps = {
  label: null,
  readOnly: false,
  hintText: null,
  disabled: false,
  small: false,
};

export default SelectField;
