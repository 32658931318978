import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import clsx from 'clsx';
import zxcvbn from 'zxcvbn';

import { makeStyles } from '@material-ui/core/styles';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Label from '../Label';
import PasswordScore from '../PasswordScore';

const useStyles = makeStyles({
  text: {
    marginTop: '8px',
  },
  small: {
    maxWidth: '240px',
  },
});

const PasswordField = ({
  name,
  small,
  hintText,
  prefix,
  suffix,
  disabled,
  readOnly,
  ...props
}) => {
  const classes = useStyles();
  const [passwordScore, setPasswordScore] = useState(false);

  return (
    <Field
      name={name}
      render={({ field, form: { errors, values, touched, submitCount } }) => {
        const errorMessage =
          ((touched && touched[field.name]) || submitCount > 0) &&
          errors &&
          errors[field.name];

        const handleChange = e => {
          const strength = zxcvbn(e.target.value, [values.name, values.email]);
          setPasswordScore(strength.score);
          field.onChange(e);
        };

        return (
          <FormControl fullWidth error={!!errorMessage}>
            <Label {...field} {...props} />

            <OutlinedInput
              classes={{
                root: clsx(classes.text, { [classes.small]: small }),
              }}
              name={name}
              type="password"
              error={!!errorMessage}
              disabled={disabled}
              readOnly={readOnly}
              {...field}
              onChange={handleChange}
            />

            <PasswordScore score={passwordScore} />

            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}

            {hintText && <FormHelperText>{hintText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  label: PropTypes.string,
  hintText: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

PasswordField.defaultProps = {
  small: false,
  disabled: false,
  prefix: null,
  label: null,
  suffix: null,
  hintText: null,
  readOnly: false,
};

export default PasswordField;
