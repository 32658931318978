import * as yup from 'yup';
import 'yup-phone-lite';

const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/pdf',
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const FILE_SIZE = 10000000; // 10mb

const validate = (schema) => yup.object().shape(schema);

const requiredString = yup
  .string('This field is required.')
  .required('This field is required.')
  .nullable();

const requiredNumber = yup
  .number('This field is required.')
  .required('This field is required.')
  .nullable();

const requiredPhone = yup
  .string()
  .phone('UK', 'Please enter a valid UK phone number (e.g. +447445123456)')
  .required('This field is required.')
  .nullable();

const conditionalNumber = (field, value) =>
  yup.number().when(field, { is: value, then: requiredNumber }).nullable();

const conditionalString = (field, value) =>
  yup.string().when(field, { is: value, then: requiredString }).nullable();

const requiredFile = yup
  .mixed()
  .required('This field is required.')
  .test(
    'fileSize',
    'File Size is too large',
    (value) => value && value.size <= FILE_SIZE,
  )
  .test(
    'fileType',
    'Unsupported File Format',
    (value) => value && SUPPORTED_FORMATS.includes(value.type),
  );

export {
  validate,
  requiredString,
  requiredNumber,
  requiredPhone,
  conditionalNumber,
  conditionalString,
  requiredFile,
};
