/**
 * @format
 * @flow
 */

import gql from 'graphql-tag';

const GET_JOBS = gql`
  query GetJobs {
    jobs {
      id
      name
      jobType {
        id
        name
        incomeTag {
          id
        }
        expenseTag {
          id
        }
      }
    }
  }
`;

const GET_USER_JOBS = gql`
  query GetUserJobs($userId: ID!) {
    jobs(userId: $userId) {
      id
      name
      jobType {
        id
        name
        incomeTag {
          id
        }
        expenseTag {
          id
        }
      }
    }
  }
`;

const GET_CUSTOM_JOBS = gql`
  query GetCustomJobs($isCommonToUsers: Boolean, $userId: ID) {
    jobs(isCommonToUsers: $isCommonToUsers, userId: $userId) {
      id
      name
      jobType {
        id
        name
        incomeTag {
          id
        }
        expenseTag {
          id
        }
      }
    }
  }
`;

const GET_JOB = gql`
  query getJob($jobId: ID!) {
    job(jobId: $jobId) {
      id
      name
      jobType {
        id
        name
        incomeTag {
          id
        }
        expenseTag {
          id
        }
      }
    }
  }
`;

const CREATE_JOB = gql`
  mutation CreateJob($name: String!, $jobType: String!, $userId: ID) {
    createJob(jobType: $jobType, name: $name, userId: $userId) {
      id
      name
      jobType {
        id
        name
      }
    }
  }
`;

const REMOVE_JOB = gql`
  mutation RemoveJob($jobId: ID!) {
    removeJob(jobId: $jobId) {
      success
    }
  }
`;

const UPDATE_JOB = gql`
  mutation UpdateJob($jobId: ID!, $name: String!, $jobType: String) {
    updateJob(jobId: $jobId, name: $name, jobType: $jobType) {
      id
      name
      jobType {
        id
        name
      }
    }
  }
`;

export {
  GET_JOBS,
  GET_CUSTOM_JOBS,
  GET_JOB,
  CREATE_JOB,
  REMOVE_JOB,
  UPDATE_JOB,
  GET_USER_JOBS,
};
