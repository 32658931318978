import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import OnboardingHeader from '../OnboardingHeader';

import { ReactComponent as FinmoIcon } from '../../assets/finmo-icon.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 30,
  },

  icons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto 30px auto',
    maxWidth: 300,
    position: 'relative',

    '&:after': {
      content: "''",
      position: 'absolute',
      top: '50%',
      right: 0,
      left: 0,
      transform: 'translateY(-50%)',
      border: `dashed 1px ${theme.palette.grey[300]}`,
      zIndex: 1,
    },
  },

  check: {
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,

    '& > svg': {
      width: 30,
      height: 30,
      display: 'block',
      fill: theme.palette.success.main,
    },
  },

  icon: {
    width: '33.33%',
    flex: '0 0 33.33%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100%',
    position: 'relative',
    paddingBottom: '33.33%',
    boxShadow: '0px 0px 100px rgba(0, 0, 0, 0.1)',
    zIndex: 2,

    '& > svg, & > img': {
      position: 'absolute',
      display: 'block',
      width: '50%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
}));

const AuthoriseHeader = ({
  subheader,
  clientLogoSrc,
  clientColor,
  clientName,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.icons}>
        <div className={classes.icon} style={{ backgroundColor: clientColor }}>
          <img src={clientLogoSrc} alt={clientName} className={classes.logo} />
        </div>

        <div className={classes.icon}>
          <FinmoIcon />
        </div>

        <div className={classes.check}>
          <CheckCircleIcon />
        </div>
      </div>
      <OnboardingHeader
        header={`Authorise ${clientName}`}
        subheader={subheader}
      />
    </div>
  );
};

AuthoriseHeader.propTypes = {
  clientName: PropTypes.string.isRequired,
  clientLogoSrc: PropTypes.string.isRequired,
  clientColor: PropTypes.string.isRequired,
  subheader: PropTypes.string,
};

AuthoriseHeader.defaultProps = {
  subheader: null,
};

export default AuthoriseHeader;
