import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as HelpIcon } from '../../assets/help.svg';

const useStyles = makeStyles({
  helpButton: {
    width: 14,
    height: 14,
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    position: 'relative',
    marginLeft: 6,
    top: 1,

    '&:after': {
      content: "''",
      position: 'absolute',
      top: -10,
      left: -10,
      bottom: -10,
      right: -10,
    },

    '& svg': {
      width: 14,
      height: 14,
      display: 'block',
    },
  },
  large: {
    width: 18,
    height: 18,

    '& svg': {
      width: 18,
      height: 18,
    },
  },
});

const HelpButton = ({ large, ...props }) => {
  const classes = useStyles();
  return (
    <button
      className={clsx(classes.helpButton, { [classes.large]: large })}
      type="button"
      {...props}
    >
      <HelpIcon />
    </button>
  );
};

HelpButton.propTypes = {
  large: PropTypes.bool,
};

HelpButton.defaultProps = {
  large: false,
};

export default HelpButton;
