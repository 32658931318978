const statusOrder = {
  READY_FOR_REVIEW: 1,
  READY_TO_FILE: 2,
  AWAITING_INFORMATION: 3,
  AWAITING_APPROVAL: 4,
  OPEN: 5,
  PREPARING: 6,
};

const getFilteredSelfAssessments = (data, { search, status }, currentYear) => {
  const selfAssessments =
    data?.assignedSelfAssessments || data?.allSelfAssessments;

  if (!selfAssessments)
    return { selfAssessmentData: [], pastSelfAssessmentData: [] };

  const sortableData = selfAssessments.map((item) => item);

  const sortedData = sortableData.sort((a, b) => {
    if (statusOrder[a.status] > statusOrder[b.status]) {
      return 1;
    }

    if (statusOrder[a.status] < statusOrder[b.status]) {
      return -1;
    }

    return 0;
  });

  const selfAssessmentData = sortedData.filter(
    (item) =>
      item.taxYear.year === currentYear &&
      (!search ||
        item.user?.name.toLowerCase().includes(search.toLowerCase())) &&
      (!status || item.status === status),
  );

  return selfAssessmentData;
};

export default getFilteredSelfAssessments;
