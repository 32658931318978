const formatFieldValue = (value, type) => {
  if (value === null) return '';

  if (type === 'number') {
    const valueAsNumber = parseFloat(value);

    if (Number.isNaN(valueAsNumber)) {
      return value;
    }

    if (Number.isInteger(valueAsNumber)) return valueAsNumber;

    return valueAsNumber.toFixed(2);
  }

  return value;
};

export default formatFieldValue;
