import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

import { handleFormSuccess, handleFormError } from '../../utils';

const Notifier = ({
  loading,
  error,
  success,
  successMessage,
  errorMessage,
  ...props
}) => {
  useEffect(() => {
    if (error && !loading) {
      handleFormError(props, error, errorMessage);
      return;
    }

    if (!loading && success) {
      handleFormSuccess(props, successMessage);
    }
  }, [error, success, loading, errorMessage, successMessage, props]);

  return <></>;
};

Notifier.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  success: PropTypes.bool,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
};

Notifier.defaultProps = {
  loading: false,
  success: false,
  error: null,
  successMessage: 'Updated successfully',
  errorMessage: null,
};

export default withSnackbar(memo(Notifier));
