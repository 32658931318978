/**
 * @format
 * @flow
 */

import gql from 'graphql-tag';

const GET_HISTORY = gql`
  query GetLastRoute {
    lastRouteName @client
  }
`;

const GET_MENU_STATE = gql`
  query GetMenuState {
    menuState @client {
      main
      myFino
      settings
      marketPlace
      help
    }
  }
`;

const GET_MODAL_STATE = gql`
  query GetMenuState {
    modalState @client {
      downloadPrompt
      callbackPrompt
    }
  }
`;

const GET_HELP_STATE = gql`
  query GetHelpState {
    helpState @client {
      overview
      incomejobs
      incomeuntaggedtransactions
      incomepersonaltransactions
      incomerentaltransactions
      incomerentalquestions
      incomeinvestmenttransactions
      incomeinvestmentquestions
      incomeearnertransactions
      incomeearnerquestions
      incomefreelancetransactions
      incomefreelancequestions
      expensesjobs
      expensesuntaggedtransactions
      expensespersonaltransactions
      expensesrentaltransactions
      expensesrentalquestions
      expensesfreelancetransactions
      expensesfreelancequestions
      exportTransactions
      taxRelieftaxRelieftransactions
      taxRelieftaxReliefquestions
    }
  }
`;

const GET_NOTES_STATE = gql`
  query GetMenuState {
    notesOpen @client
  }
`;

const GET_TODO_STATE = gql`
  query GetMenuState {
    todoClosed @client
  }
`;

export {
  GET_HISTORY,
  GET_MENU_STATE,
  GET_NOTES_STATE,
  GET_HELP_STATE,
  GET_TODO_STATE,
  GET_MODAL_STATE,
};
