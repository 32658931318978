import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as HelpIcon } from '../../assets/help.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '30px 24px',
    display: 'flex',

    '&:first-child': {
      paddingTop: 50,
    },
  },
  pink: {
    backgroundColor: theme.palette.secondary.light,
  },
  icon: {
    width: 40,
    flex: '0 0 40px',

    '& svg': {
      display: 'block',
      width: 16,
      height: 16,
      marginLeft: 4,
    },
  },
  largeIcon: {
    '& svg': {
      width: 24,
      height: 24,
      marginLeft: 0,
    },
  },
  copy: {},
  title: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    fontWeight: 300,
    color: theme.palette.common.black,
  },
  noMarginBottom: {
    paddingBottom: 0,
  },
}));

const OnboardingHelpBlock = ({
  title,
  copy,
  pink,
  icon,
  noMarginBottom,
  largeIcon,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, {
        [classes.pink]: pink,
        [classes.noMarginBottom]: noMarginBottom,
      })}
    >
      <div className={clsx(classes.icon, { [classes.largeIcon]: largeIcon })}>
        {icon || <HelpIcon />}
      </div>
      <div className={classes.copy}>
        {!!title && (
          <Typography className={classes.title} variant="h4">
            {title}
          </Typography>
        )}
        {!!copy && (
          <Typography className={classes.text} variant="body1">
            {copy}
          </Typography>
        )}
      </div>
    </div>
  );
};

OnboardingHelpBlock.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  pink: PropTypes.bool,
  icon: PropTypes.node,
  noMarginBottom: PropTypes.bool,
  largeIcon: PropTypes.bool,
};

OnboardingHelpBlock.defaultProps = {
  title: null,
  copy: null,
  icon: null,
  pink: false,
  noMarginBottom: false,
  largeIcon: false,
};

export default OnboardingHelpBlock;
