const getTransactionCategorisationData = (
  autoCategorised = 0,
  manuallyCategorised = 0,
  uncategorised = 0,
) => {
  if (!autoCategorised && !manuallyCategorised && !uncategorised) return null;

  const total = autoCategorised + manuallyCategorised + uncategorised;

  return [
    {
      name: 'Transactions',
      Rules: parseInt((autoCategorised / total) * 100, 10),
      Manual: parseInt((manuallyCategorised / total) * 100, 10),
      Uncategorised: parseInt((uncategorised / total) * 100, 10),
    },
  ];
};

export default getTransactionCategorisationData;
