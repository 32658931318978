const getIncomeAnswersFromSummary = data => {
  if (!data || !data.length) return {};

  const groupedJobs = data.reduce(
    (a, job) => ({
      ...a,
      [job.job.jobType.name]: [...(a[job.job.jobType.name] || []), job],
    }),
    {},
  );

  return {
    fullTimeEmployment: groupedJobs.earner ? 'Yes' : 'No',
    numberOfEmploymentJobs: groupedJobs?.earner?.length || 0,
    selfEmployed: groupedJobs?.freelance ? 'Yes' : 'No',
    numberOfSelfEmploymentBusinesses: groupedJobs?.freelance?.length || 0,
    property: groupedJobs?.rental ? 'Yes' : 'No',
    numberOfproperties: groupedJobs?.rental?.length || 0,
    capitalGains: groupedJobs?.investment ? 'Yes' : 'No',
    numberOfCapitalGains: groupedJobs?.investment?.length || 0,
  };
};

export default getIncomeAnswersFromSummary;
