const filterJobsByDocumentType = (data, documentGroup) => {
  if (!data || !data.jobs || !documentGroup) {
    return {
      disabled: true,
      jobs: [
        {
          id: 0,
          name: 'Please select a document type',
        },
      ],
    };
  }

  if (
    documentGroup === 'P45' ||
    documentGroup === 'P60' ||
    documentGroup === 'P45 or P60' ||
    documentGroup === 'P11D'
  ) {
    return {
      disabled: false,
      jobs: [
        {
          id: '',
          name: 'Select job',
        },

        ...data.jobs.filter(job => job.jobType.name === 'earner'),
      ],
    };
  }

  if (documentGroup === 'Receipt' || documentGroup === 'Invoice') {
    return {
      disabled: false,
      jobs: [
        {
          id: '',
          name: 'Select job',
        },

        ...data.jobs.filter(
          job =>
            job.jobType.name === 'freelance' || job.jobType.name === 'rental',
        ),
      ],
    };
  }

  if (documentGroup === 'Signed rental agreement') {
    return {
      disabled: false,
      jobs: [
        {
          id: '',
          name: 'Select job',
        },

        ...data.jobs.filter(job => job.jobType.name === 'rental'),
      ],
    };
  }

  if (documentGroup === 'Proof of Investment') {
    return {
      disabled: false,
      jobs: [
        {
          id: '',
          name: 'Select job',
        },

        ...data.jobs.filter(job => job.jobType.name === 'investment'),
      ],
    };
  }

  return {
    disabled: true,
    jobs: [
      {
        id: 0,
        name: 'No income source required',
      },
    ],
  };
};

export default filterJobsByDocumentType;
