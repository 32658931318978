import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },

  header: {
    color: theme.palette.common.white,
    fontWeight: 200,
    fontSize: 48,
    marginBottom: 35,
  },

  body: {
    color: theme.palette.common.white,
    marginBottom: 0,
    lineHeight: '28px',

    '&:last-child': {
      marginBottom: 0,
    },
  },

  list: {
    listStyle: 'none',
    padding: '10px 0',
    margin: 0,
  },

  listItem: {
    padding: '10px 0',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },

    '& svg': {
      flex: '0 0 45px',
      margin: '0 0 10px',

      [theme.breakpoints.up('md')]: {
        margin: '4px 20px 0 0',
      },
    },
  },
}));

const OnboardingHint = ({ header, body, list }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.header} variant="h3">
        {header}
      </Typography>

      {body && (
        <Typography className={classes.body} variant="body1">
          {body}
        </Typography>
      )}

      {list && (
        <ul className={classes.list}>
          {list.map(item => (
            <li key={item.id} className={classes.listItem}>
              {item.icon}

              <Typography className={classes.body} variant="body1">
                {item.title && <strong>{item.title}</strong>}
                {item.content}
              </Typography>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

OnboardingHint.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.node,
  list: PropTypes.arrayOf(PropTypes.shape({})),
};

OnboardingHint.defaultProps = {
  body: null,
  list: null,
};

export default OnboardingHint;
