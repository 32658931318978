import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as ShieldIcon } from '../../assets/shield.svg';

const useStyles = makeStyles((theme) => ({
  footer: {
    height: 40,
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    fontSize: 12,
    textTransform: 'uppercase',
    letterSpacing: '0.15em',
    fontWeight: 300,
    position: 'fixed',
    zIndex: theme.zIndex.drawer - 1,

    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },

    '& strong': {
      fontWeight: 500,
      display: 'inline-block',
      marginRight: 5,
    },

    '& svg': {
      display: 'block',
      height: 16,
      marginRight: 10,
    },
  },
}));

const AuthFooter = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <ShieldIcon />
      <strong>Regulated by the FCA</strong> – Ref no: 800637
    </footer>
  );
};

export default AuthFooter;
