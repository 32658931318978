import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Label from '../Label';

import { formatFieldValue } from '../../utils';

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: '8px',
  },
  small: {
    maxWidth: '240px',
  },
  adornment: {
    whiteSpace: 'nowrap',
    '& p': {
      lineHeight: '1.1875em',
    },
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
}));

const Text = ({
  name,
  small,
  hintText,
  type,
  prefix,
  suffix,
  disabled,
  readOnly,
  defaultValue,
  compact,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Field
      name={name}
      render={({ field, form: { errors, touched, submitCount } }) => {
        const errorMessage =
          ((touched && touched[field.name]) || submitCount > 0) &&
          errors &&
          errors[field.name];
        const textareaProps =
          type === 'textarea' ? { multiline: true, rows: compact ? 2 : 4 } : {};

        return (
          <FormControl fullWidth error={!!errorMessage}>
            <Label {...field} {...props} />

            <OutlinedInput
              classes={{
                root: clsx(classes.text, { [classes.small]: small }),
              }}
              startAdornment={
                prefix && (
                  <InputAdornment
                    position="start"
                    className={classes.adornment}
                  >
                    {prefix}
                  </InputAdornment>
                )
              }
              endAdornment={
                suffix && (
                  <InputAdornment position="end" className={classes.adornment}>
                    {suffix}
                  </InputAdornment>
                )
              }
              {...textareaProps}
              name={name}
              type={type}
              error={!!errorMessage}
              disabled={disabled}
              readOnly={readOnly}
              {...field}
              value={formatFieldValue(defaultValue || field.value, type)}
            />

            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}

            {hintText && <FormHelperText>{hintText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

Text.propTypes = {
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
  type: PropTypes.string.isRequired,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffix: PropTypes.string,
  label: PropTypes.string,
  hintText: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.string,
  compact: PropTypes.bool,
};

Text.defaultProps = {
  small: false,
  disabled: false,
  prefix: null,
  label: null,
  suffix: null,
  hintText: null,
  readOnly: false,
  defaultValue: '',
  compact: false,
};

export default Text;
