import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSpring, animated } from 'react-spring';
import { makeStyles } from '@material-ui/core/styles';

import Loader from '../Loader';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    minHeight: '100vh',

    [theme.breakpoints.up('sm')]: {
      backgroundColor: 'transparent',
      padding: 50,
    },
  },
  contentContainer: {
    position: 'relative',
    padding: 40,
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '540px',
      margin: 'auto',
      width: '100%',
      padding: 0,
    },
  },
}));

const LayoutAuthorise = ({ children, fetching }) => {
  const classes = useStyles();

  const style = useSpring({
    opacity: fetching ? 0 : 1,
    transform: fetching ? 'translate(0, 40px)' : 'translate(0, 1px)',
  });

  return (
    <div className={classes.container}>
      <main className={clsx(classes.contentContainer)}>
        {fetching && <Loader />}

        <animated.div style={style}>{children}</animated.div>
      </main>
    </div>
  );
};

LayoutAuthorise.propTypes = {
  children: PropTypes.node.isRequired,
  fetching: PropTypes.bool,
};

LayoutAuthorise.defaultProps = {
  fetching: false,
};

export default LayoutAuthorise;
