import React from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { Link } from '@reach/router';

import Typography from '@material-ui/core/Typography';
import LayoutAuth from '../../components/LayoutAuth';
import Form from '../../components/Form';
import OnboardingPrompt from '../../components/OnboardingPrompt';
import OnboardingHeader from '../../components/OnboardingHeader';
import OnboardingHint from '../../components/OnboardingHint';

import { loginForm } from '../../config/FormFields';

import { validate, requiredString } from '../../utils/validate';

import { CREATE_TOKEN, GET_TOKEN, IS_AUTHENTICATED } from '../../graph/auth';

const loginFormValidationSchema = validate({
  email: requiredString,
  password: requiredString,
});

const loginFormInitialValues = {
  email: '',
  password: '',
};

const Login = () => {
  const client = useApolloClient();
  const [handleLogin, { loading, error }] = useMutation(CREATE_TOKEN, {
    async onCompleted({ logIn }) {
      if (!logIn) return;

      client.cache.writeQuery({
        query: GET_TOKEN,
        data: logIn,
      });

      await client.cache.writeQuery({
        query: IS_AUTHENTICATED,
        data: {
          isAuthenticated: true,
        },
      });
    },
  });

  const renderSideContent = () => {
    return (
      <OnboardingHint
        header="Keep on top of your income and expenses"
        body={
          <>
            Finmo allows you to manage your income and expenses throughout the
            year and simply file your Self Assessment when the time comes.
            <br />
            <br />
            Designed for freelancers, sole-traders and those with multiple
            sources of income and want to save time and money when filing their
            tax return.
          </>
        }
      />
    );
  };

  return (
    <LayoutAuth renderSideContent={renderSideContent}>
      <OnboardingHeader
        header="Sign in to Finmo"
        subheader="Sign in with your email and password"
      />

      <Form
        noMobilePadding
        noCard
        fields={loginForm}
        loading={loading}
        error={error}
        onSubmit={handleLogin}
        validationSchema={loginFormValidationSchema}
        initialValues={loginFormInitialValues}
        noActionBar
        button={{
          label: 'Log in',
          color: 'secondary',
          variant: 'contained',
          fullWidth: true,
        }}
        maxWidthButton
        narrow
      />

      <Typography align="center" gutterBottom color="primary">
        <Link style={{ color: 'inherit' }} to="/reset-password">
          Forgot your password?
        </Link>
      </Typography>

      <OnboardingPrompt
        title="New to Finmo?"
        button="Create your Finmo account"
        to="/register"
      />
    </LayoutAuth>
  );
};

export default Login;
