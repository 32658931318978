import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  absolute: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  inline: {
    height: '40px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
});

const Loader = ({ inline }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.absolute]: !inline,
        [classes.inline]: inline,
      })}
    >
      <CircularProgress />
    </div>
  );
};

Loader.propTypes = {
  inline: PropTypes.bool,
};

Loader.defaultProps = {
  inline: false,
};

export default Loader;
