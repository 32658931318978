import React from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { Link } from '@reach/router';
import queryString from 'query-string';

import Typography from '@material-ui/core/Typography';
import LayoutAuthorise from '../../components/LayoutAuthorise';
import Form from '../../components/Form';
import OnboardingPrompt from '../../components/OnboardingPrompt';
import AuthoriseHeader from '../../components/AuthoriseHeader';
import Card from '../../components/Card';
import CardContent from '../../components/CardContent';
import OnboardingHeader from '../../components/OnboardingHeader';

import { loginForm } from '../../config/FormFields';
import { validate, requiredString } from '../../utils/validate';

import { CREATE_TOKEN, GET_TOKEN, IS_AUTHENTICATED } from '../../graph/auth';
import { GET_AUTH_DATA } from '../../graph/oAuth';

const loginFormValidationSchema = validate({
  email: requiredString,
  password: requiredString,
});

const loginFormInitialValues = {
  email: '',
  password: '',
};

const Login = () => {
  const client = useApolloClient();

  const {
    scope: scopeString,
    client: clientId,
    redirect_uri: redirectUri,
    response_mode: responseMode,
    response_type: responseType,
  } = queryString.parse(window.location.search);

  const scope = scopeString?.split(' ') || [];

  const hasRequiredData =
    scopeString &&
    clientId &&
    redirectUri &&
    redirectUri &&
    responseMode &&
    responseType;

  const { data, loading: fetching } = useQuery(GET_AUTH_DATA, {
    skip: !hasRequiredData,
    variables: {
      scopeIds: scope,
      clientId,
    },
  });

  const [handleLogin, { loading, error }] = useMutation(CREATE_TOKEN, {
    onCompleted({ logIn }) {
      if (!logIn) return;

      client.cache.writeQuery({
        query: IS_AUTHENTICATED,
        data: {
          isAuthenticated: true,
        },
      });

      client.writeQuery({
        query: GET_TOKEN,
        data: logIn,
      });
    },
  });

  const {
    oauthPartner: { name, color, logoSrc },
    oauthScopes,
  } = data?.oauthPartner && data?.oauthScopes ? data : { oauthPartner: {} };

  if (!oauthScopes?.length || !hasRequiredData || !name || !color || !logoSrc) {
    return (
      <LayoutAuthorise>
        <OnboardingHeader
          header="Error authorising application"
          subheader="There has been an error authorising the application, please check the URL parameters and the Finmo oAuth documentation."
        />
      </LayoutAuthorise>
    );
  }

  return (
    <LayoutAuthorise fetching={fetching}>
      <AuthoriseHeader
        subheader="Sign in to Finmo with your email and password"
        clientName={name}
        clientLogoSrc={logoSrc}
        clientColor={color}
      />

      <Card bigShadow noActions>
        <CardContent>
          <Form
            noMobilePadding
            noCard
            fields={loginForm}
            loading={loading}
            error={error}
            onSubmit={handleLogin}
            validationSchema={loginFormValidationSchema}
            initialValues={loginFormInitialValues}
            noActionBar
            button={{
              label: 'Log in',
              color: 'secondary',
              variant: 'contained',
              fullWidth: true,
            }}
          />

          <Typography align="center" gutterBottom color="primary">
            <Link style={{ color: 'inherit' }} to="/reset-password">
              Forgot your password?
            </Link>
          </Typography>

          <OnboardingPrompt
            title="New to Finmo?"
            button="Create your Finmo account"
            to="/register"
          />
        </CardContent>
      </Card>
    </LayoutAuthorise>
  );
};

export default Login;
