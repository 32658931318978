import {
  taxRelief,
  freelanceExpenses,
  freelance,
  paye,
  rental,
  investment,
} from '../config/FormFields';

const getSelfAssessmentFields = (type, jobType) => {
  if (type === 'taxRelief') {
    return taxRelief;
  }

  if (type === 'expenses' && jobType === 'freelance') {
    return freelanceExpenses;
  }

  if (jobType === 'freelance') {
    return freelance;
  }

  if (jobType === 'rental') {
    return rental;
  }

  if (jobType === 'earner') {
    return paye;
  }

  if (jobType === 'investment') {
    return investment;
  }

  return {};
};

export default getSelfAssessmentFields;
