import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  title: {
    padding: '0 0 20px 0',
    textAlign: 'center',
    maxWidth: '500px',
    margin: '0 auto',
    color: 'black',
    fontWeight: 900,
    fontSize: 36,
  },

  white: {
    color: theme.palette.common.white,
  },
}));

const LandingTitle = ({ children, white, ...props }) => {
  const classes = useStyles();

  return (
    <Typography
      variant="h3"
      align="center"
      className={clsx(classes.title, { [classes.white]: white })}
      {...props}
    >
      {children}
    </Typography>
  );
};

LandingTitle.propTypes = {
  children: PropTypes.node.isRequired,
  white: PropTypes.bool,
};

LandingTitle.defaultProps = {
  white: false,
};

export default LandingTitle;
