import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { isMobileDevice } from '../../utils';

const useStyles = makeStyles((theme) => ({
  helpIconButton: {
    width: '30px',
    height: '30px',
    position: 'relative',
    top: '-2px',
    right: '-8px',
    '& svg': {
      color: theme.palette.grey[400],
      width: '20px',
      heignt: '20px',
      transition: theme.transitions.create(['color'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
    },
    '&:hover svg': {
      color: theme.palette.secondary.main,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.grey[800],
    padding: '10px',
    fontSize: '14px',
  },
}));

const CustomTooltip = ({ helpText }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const isMobile = isMobileDevice();

  if (isMobile) {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title={helpText}
          classes={{ tooltip: classes.tooltip }}
          onClose={handleTooltipClose}
          open={open}
        >
          <IconButton
            size="small"
            className={classes.helpIconButton}
            onClick={handleTooltipOpen}
          >
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    );
  }

  return (
    <Tooltip title={helpText} classes={{ tooltip: classes.tooltip }}>
      <IconButton size="small" className={classes.helpIconButton}>
        <HelpIcon />
      </IconButton>
    </Tooltip>
  );
};

CustomTooltip.propTypes = {
  helpText: PropTypes.string.isRequired,
};

export default CustomTooltip;
