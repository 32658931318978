import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import Title from '../Title';

const FormTitle = ({ name, label, ...props }) => {
  return (
    <Field
      name={name}
      render={({ field }) => <Title {...props}>{label || field.value}</Title>}
    />
  );
};

FormTitle.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

FormTitle.defaultProps = {
  label: null,
};

export default FormTitle;
