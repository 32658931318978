import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '20px 10px 0',
    textAlign: 'center',
    maxWidth: '500px',
    margin: '0 auto',
    color: 'black',
    [theme.breakpoints.up('sm')]: {
      padding: '30px 30px 0',
    },
  },
  small: {
    padding: '30px 0 10px 0',
  },
  paddingBottom: {
    padding: '20px 10px 20px',

    [theme.breakpoints.up('sm')]: {
      padding: '30px',
    },
  },
  largePaddingTop: {
    paddingTop: '50px',
  },
  noPadding: {
    paddingTop: '0px',
  },
  smallPaddingBottom: {
    padding: '0 0 20px 0',
  },
}));

const Title = ({
  children,
  small,
  paddingBottom,
  largePaddingTop,
  noPadding,
  smallPaddingBottom,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Typography
      variant={small ? 'h6' : 'h5'}
      align="center"
      className={clsx(classes.title, {
        [classes.small]: small,
        [classes.paddingBottom]: paddingBottom,
        [classes.largePaddingTop]: largePaddingTop,
        [classes.noPadding]: noPadding,
        [classes.smallPaddingBottom]: smallPaddingBottom,
      })}
      {...props}
    >
      {children}
    </Typography>
  );
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
  paddingBottom: PropTypes.bool,
  largePaddingTop: PropTypes.bool,
  noPadding: PropTypes.bool,
  smallPaddingBottom: PropTypes.bool,
};

Title.defaultProps = {
  small: false,
  paddingBottom: false,
  largePaddingTop: false,
  noPadding: false,
  smallPaddingBottom: false,
};

export default Title;
