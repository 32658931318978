import dayjs from 'dayjs';

const getClarityDataFromUsers = (users) => {
  if (!users) {
    return {
      clarityUsers: [],
    };
  }

  const reducedClarityData = users.reduce(
    (a, { email, livingwageCalculatorMeta, createdAt, updatedAt, ...user }) => {
      if (
        livingwageCalculatorMeta === '{}' ||
        email.includes('mailinator') ||
        (process.env.REACT_APP_EXCLUDE_ANONYMOUS_ACCOUNS === 'true' &&
          email.includes('finmo')) ||
        email.includes('brierley') ||
        email.includes('jd') ||
        email.includes('jonathanmadeley') ||
        email.includes('cooknorthrop') ||
        email.includes('test') ||
        email.includes('alex_steele')
      ) {
        return a;
      }

      const parsedMeta = JSON.parse(livingwageCalculatorMeta);
      const isComplete =
        (parsedMeta.activeGroup === 'expenses' && parsedMeta.activeStep > 5) ||
        (!parsedMeta.activeGroup &&
          livingwageCalculatorMeta.includes('expenses'));

      const duration = dayjs(updatedAt).diff(dayjs(createdAt), 'minute');
      const includeDuration = isComplete && duration / 60 < 24;
      const numberOfIndustries = parsedMeta.industries?.length || 0;
      const numberOfJobs = parsedMeta.jobs
        ? Object.entries(parsedMeta.jobs).length
        : 0;

      return {
        clarityUsers: [
          ...a.clarityUsers,
          {
            ...user,
            ...parsedMeta,
            email,
            isComplete,
            duration,
            numberOfIndustries,
            numberOfJobs,
            createdAt,
            updatedAt,
          },
        ],
        totalUsers: a.totalUsers + 1,
        totalComplete: isComplete ? a.totalComplete + 1 : a.totalComplete,
        totalDurationUser: includeDuration
          ? a.totalDurationUser + 1
          : a.totalDurationUser,
        totalDuration: includeDuration
          ? a.totalDuration + duration
          : a.totalDuration,
        totalIndustries: a.totalIndustries + numberOfIndustries,
        totalJobs: a.totalJobs + numberOfJobs,
      };
    },
    {
      clarityUsers: [],
      totalUsers: 0,
      totalComplete: 0,
      totalDurationUser: 0,
      totalDuration: 0,
      totalIndustries: 0,
      totalJobs: 0,
    },
  );

  return {
    ...reducedClarityData,
    averageCompletionRate:
      (reducedClarityData.totalComplete / reducedClarityData.totalUsers) * 100,
    averageDuration:
      reducedClarityData.totalDuration / reducedClarityData.totalDurationUser,
    averageIndustries:
      reducedClarityData.totalIndustries / reducedClarityData.totalUsers,
    averageJobs: reducedClarityData.totalJobs / reducedClarityData.totalUsers,
  };
};

export default getClarityDataFromUsers;
