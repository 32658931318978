import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

import Field from '../Field';

const useStyles = makeStyles((theme) => ({
  fieldCol: {
    maxWidth: 560,
    margin: '0 auto',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      minWidth: 420,
    },
  },
  mainField: {
    marginBottom: 24,
    padding: '20px',
    border: `solid 1px ${theme.palette.grey[200]}`,
    borderRadius: '4px',
    transition: theme.transitions.create(['backgroundColor', 'borderColor'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    '&:focus-within': {
      backgroundColor: theme.palette.lightGrey.main,
      borderColor: theme.palette.grey[300],
    },
  },
  collapse: {
    maxWidth: 560,
    padding: '20px 20px 0 20px',
    borderTop: `solid 1px ${theme.palette.grey[200]}`,
    borderBottom: `solid 1px ${theme.palette.grey[200]}`,
    margin: '20px -20px 0 -20px',
    width: 'calc(100% + 40px)',
  },
  mainFieldCollapse: {
    marginBottom: '-20px',
    borderBottom: 'none',
  },
  narrow: {
    maxWidth: 440,
  },
  inline: {
    margin: '0 30px 19px',
  },
}));

const FieldWrapper = ({
  icon,
  id,
  type,
  values,
  subFields,
  altSubFields,
  subField,
  mainField,
  last,
  alignLeft,
  narrow,
  inline,
  ...field
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.fieldCol, {
        [classes.mainField]: mainField,
        [classes.narrow]: narrow,
        [classes.inline]: inline,
      })}
    >
      <Field id={id} type={type} {...field} />

      {subFields && (
        <Collapse
          in={values && subFields.value.includes(values[id])}
          timeout="auto"
          unmountOnExit
          className={clsx(classes.collapse, {
            [classes.mainFieldCollapse]: mainField || last,
          })}
        >
          {subFields.fields.map((subFieldItem, i) => (
            <FieldWrapper
              key={subFieldItem.id}
              values={values}
              type={type}
              {...subFieldItem}
              subField
              last={i + 1 === subFields.fields.length}
            />
          ))}
        </Collapse>
      )}

      {altSubFields && (
        <Collapse
          in={values && altSubFields.value.includes(values[id])}
          timeout="auto"
          unmountOnExit
          className={clsx(classes.collapse, {
            [classes.mainFieldCollapse]: mainField || last,
          })}
        >
          {altSubFields.fields.map((subFieldItem, i) => (
            <FieldWrapper
              key={subFieldItem.id}
              values={values}
              type={type}
              {...subFieldItem}
              subField
              last={i + 1 === altSubFields.fields.length}
            />
          ))}
        </Collapse>
      )}
    </div>
  );
};

FieldWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.node,

  subFields: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    value: PropTypes.string,
  }),
  altSubFields: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    value: PropTypes.string,
  }),
  values: PropTypes.shape({}),
  subField: PropTypes.bool,
  mainField: PropTypes.bool,
  last: PropTypes.bool,
  alignLeft: PropTypes.bool,
  narrow: PropTypes.bool,
  inline: PropTypes.bool,
};

FieldWrapper.defaultProps = {
  icon: null,
  subFields: null,
  altSubFields: null,
  values: {},
  subField: false,
  mainField: false,
  last: false,
  alignLeft: false,
  narrow: false,
  inline: false,
};

export default FieldWrapper;
