const groupStatusTotals = (data) => {
  if (!data?.allSelfAssessments?.length) return {};

  return data?.allSelfAssessments?.reduce((a, { status }) => {
    return {
      ...a,
      [status]: (a[status] || 0) + 1,
    };
  }, {});
};

export default groupStatusTotals;
