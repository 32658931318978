import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Label from '../Label';

const useStyles = makeStyles(theme => ({
  text: {
    marginTop: '8px',
  },
  small: {
    maxWidth: '240px',
  },
  adornment: {
    whiteSpace: 'nowrap',
    '& p': {
      lineHeight: '1.1875em',
    },
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
}));

const InvestmentTotalField = ({
  name,
  small,
  hintText,
  type,
  prefix,
  suffix,
  disabled,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Field
      name={name}
      render={({ field, form: { values } }) => {
        const value =
          values.purchaseAmount && values.saleAmount && values.expenses
            ? values.saleAmount - values.purchaseAmount - values.expenses
            : 0;

        return (
          <FormControl fullWidth>
            <Label {...field} {...props} />

            <OutlinedInput
              classes={{
                root: clsx(classes.text, { [classes.small]: small }),
              }}
              startAdornment={
                prefix && (
                  <InputAdornment
                    position="start"
                    className={classes.adornment}
                  >
                    {prefix}
                  </InputAdornment>
                )
              }
              name={name}
              type="number"
              value={value}
              disabled
            />

            {hintText && <FormHelperText>{hintText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

InvestmentTotalField.propTypes = {
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
  type: PropTypes.string.isRequired,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffix: PropTypes.string,
  label: PropTypes.string,
  hintText: PropTypes.string,
  disabled: PropTypes.bool,
};

InvestmentTotalField.defaultProps = {
  small: false,
  disabled: false,
  prefix: null,
  label: null,
  suffix: null,
  hintText: null,
};

export default InvestmentTotalField;
