import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MaterialCardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: '30px',
  },
  noMobilePadding: {
    padding: '30px 0',
    [theme.breakpoints.up('sm')]: {
      padding: '30px',
    },
  },
  cardContentLargePadding: {
    padding: '30px',
    [theme.breakpoints.up('sm')]: {
      padding: '30px  60px',
    },
  },
  cardContentNoPadding: {
    padding: 0,

    '&:last-child': {
      paddingBottom: 0,
    },
  },
  narrow: {
    maxWidth: '800px',
    margin: '0 auto',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const CardContent = ({
  children,
  largePadding,
  noPadding,
  narrow,
  noMobilePadding,
  flex,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MaterialCardContent
      className={clsx({
        [classes.cardContent]: !largePadding,
        [classes.cardContentLargePadding]: largePadding,
        [classes.cardContentNoPadding]: noPadding,
        [classes.narrow]: narrow,
        [classes.noMobilePadding]: noMobilePadding,
        [classes.flex]: flex,
      })}
      {...props}
    >
      {children}
    </MaterialCardContent>
  );
};

CardContent.propTypes = {
  children: PropTypes.node.isRequired,
  largePadding: PropTypes.bool,
  noPadding: PropTypes.bool,
  narrow: PropTypes.bool,
  noMobilePadding: PropTypes.bool,
  flex: PropTypes.bool,
};

CardContent.defaultProps = {
  largePadding: false,
  noPadding: false,
  narrow: false,
  noMobilePadding: false,
  flex: false,
};

export default CardContent;
